import Decimal from 'decimal.js';

export const ZERO = new Decimal('0.00');
export const ONE = new Decimal('1.00');
export const TEN = new Decimal('10.00');
export const HUNDRED = new Decimal('100.00');
export const THOUSAND = new Decimal('1000.00');
export const DEBOUNCE_TIME = 3000;
export const SelectedLevels = {
  accountGroup: 'account group',
  account: 'account',
};

export const ViewLevels = {
  accountGroups: 'accountGroups',
  accounts: 'accounts',
};
