import { AppDispatch } from '@app/app/store';
import {
  TABLE_CONFIG_CODES,
  TableConfigExtended,
} from '@app/features/TableConfig';
import { debounceSaveTableConfig } from '@app/features/TableConfig/tableConfigSlice.helpers';
import { modifyColumnsStatePayload } from '@app/utils/modifiers.utils';
import { SharedColumnData } from '@bsa/shared-types';
import {
  ColumnMovedEvent,
  ColumnVisibleEvent,
  SortChangedEvent,
} from 'ag-grid-community';

export type BSAColumnState =
  | SortChangedEvent
  | ColumnVisibleEvent
  | ColumnMovedEvent;
export function updateColumnsState(
  event: BSAColumnState,
  positionsTableConfig: TableConfigExtended,
  dispatch: AppDispatch,
) {
  const columnState = event.api.getColumnState() as SharedColumnData[];
  const payload = modifyColumnsStatePayload(
    columnState,
    positionsTableConfig.code as keyof typeof TABLE_CONFIG_CODES,
    positionsTableConfig.columnIdsMapping,
    positionsTableConfig.config_id,
  );
  debounceSaveTableConfig(dispatch, payload);
}
