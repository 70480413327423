import {
  FormFieldStatus,
  IressInline,
  MenuItemMeta,
} from '@iress/components-react';
import { ComboBox } from '../ComboBox';
import './SwitchingToSecurity.css';

function SwitchingToSecurity(
  props: Readonly<{
    id: string;
    cbHandleInputChange: (query: string) => Promise<MenuItemMeta[]>;
    cbDataTestId?: string;
    cbName?: string;
    cbNoResultsText?: string;
    cbSelectedOption?: MenuItemMeta;
    cbOnchange?: (e: FormFieldStatus<HTMLElement>) => void;
  }>,
) {
  return (
    <IressInline
      gutter={IressInline.Gutter.Sm}
      verticalAlign={IressInline.VerticalAlign.Middle}
      horizontalAlign={IressInline.HorizontalAlign.Left}
      id={props.id}
      data-testid={props.id}
    >
      <ComboBox
        handleInputChange={props.cbHandleInputChange}
        noResultsText={props.cbNoResultsText}
        selectedOption={props.cbSelectedOption}
        dataTestId={props.cbDataTestId}
        name={props.cbName}
        className="switching-to-security-combobox"
        placeholder="Search for a security"
        onChange={props.cbOnchange}
      />
    </IressInline>
  );
}
export default SwitchingToSecurity;
