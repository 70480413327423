import { useAppSelector } from '@app/app/hooks';
import {
  symbolColumnDef,
  updateCurrencyTitleInColumns,
  volumeColumnDef,
} from '@app/components/AgGrid';
import {
  getSelectedRowIdsSet,
  getSwitchRowId,
} from '@app/utils/modifiers.utils';
import { SharedSwitchAdjustment } from '@bsa/shared-types';
import {
  ColDef,
  ColumnState,
  FirstDataRenderedEvent,
  GetRowIdParams,
  GroupSelectionMode,
  IGroupCellRendererParams,
  RowSelectionOptions,
  SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
} from 'ag-grid-community';
import { useCallback, useMemo } from 'react';
import { getSwitchTypeValue } from '../SwitchGrid.utils';

export function useSwitchGrid() {
  const security = useAppSelector((state) => state.securities.selectedSecurity);
  const selectedSwitches = useAppSelector(
    (state) => state.switch.selectedSwitches,
  );
  const switchTableConfig = useAppSelector(
    (state) => state.tableConfig.data.switch_accounts,
  );
  const columnDefs = useMemo((): ColDef<SharedSwitchAdjustment>[] => {
    return [
      { field: 'security', autoHeight: true, hide: true },
      { field: 'account', autoHeight: true, hide: true },
      { field: 'portfolio', autoHeight: true },
      {
        field: 'switchType',
        autoHeight: true,
        valueGetter: getSwitchTypeValue,
      },
      {
        field: 'currentValue (CCY)',
        ...symbolColumnDef,
      },
      {
        field: 'targetValue (CCY)',
        ...symbolColumnDef,
      },
      {
        field: 'proposedValue (CCY)',
        ...symbolColumnDef,
      },
      {
        field: 'currentUnits',
        ...volumeColumnDef,
      },
      {
        field: 'proposedUnits',
        ...volumeColumnDef,
      },
      { field: 'accountId', hide: true },
      { field: 'securityId', hide: true },
    ];
  }, []);

  const autoGroupColumnDef = useMemo<ColDef>(() => {
    return {
      headerName: 'Accounts & Securities',
      minWidth: 300,
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        checkbox: true,
        suppressCount: true,
      } as IGroupCellRendererParams,
    };
  }, []);

  updateCurrencyTitleInColumns(columnDefs, security);

  const getRowId = useCallback(
    (params: GetRowIdParams<SharedSwitchAdjustment>) =>
      getSwitchRowId(params.data),
    [],
  );

  const getDataPath = useCallback(
    (data: SharedSwitchAdjustment) => data.hierarchy,
    [],
  );

  const rowSelection: RowSelectionOptions = useMemo(
    () => ({
      mode: 'multiRow',
      headerCheckbox: false, // Enable header checkbox only when applicable
      checkboxes: false, // Enable checkboxes only when applicable
      groupSelects: 'descendants' as GroupSelectionMode,
      enableClickSelection: false,
    }),
    [],
  );

  const onFirstDataRendered = useCallback(
    (params: FirstDataRenderedEvent<SharedSwitchAdjustment>) => {
      params.api.applyColumnState({
        state: switchTableConfig.data as ColumnState[],
        applyOrder: true,
      });
      // Convert selectedRowIds to a Set for faster lookup
      const selectedRowIdsSet = getSelectedRowIdsSet(selectedSwitches);
      params.api.forEachNode((node) => {
        const rowId = node.id; // This uses the rowId as defined in getRowId
        if (rowId && selectedRowIdsSet.has(rowId)) {
          node.setSelected(true);
        }
      });
    },
    [selectedSwitches, switchTableConfig],
  );
  const autoSizeStrategy = useMemo<
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy
    | SizeColumnsToContentStrategy
  >(() => {
    return {
      type: 'fitGridWidth',
    };
  }, []);
  return {
    columnDefs,
    autoGroupColumnDef,
    getRowId,
    getDataPath,
    rowSelection,
    onFirstDataRendered,
    autoSizeStrategy,
  };
}
