import { IressPanel, IressStack, IressText } from '@iress/components-react';
import { useAppDispatch, useAppSelector } from '@app/app/hooks';
import { RootState, AppDispatch } from '@app/app/store';
import { Spinner } from '@app/components/Spinner';
import {
  AgGrid,
  BSAColumnState,
  updateColumnsState,
} from '@app/components/AgGrid';
import {
  ColDef,
  IRowNode,
  SelectionChangedEvent,
  FirstDataRenderedEvent,
  GetRowIdParams,
  FilterChangedEvent,
  ColumnState,
} from 'ag-grid-community';
import type {
  AccountGroupLevelResponse,
  AccountLevelResponse,
} from '@bsa/shared-types';
import { RefObject, useCallback, useEffect, useMemo } from 'react';
import {
  AccountGroupsState,
  setSelectedPositions,
  updatePositionSelection,
} from '@app/features/AccountGroups';
import { SharedViewLevelParamExtended } from '@app/types/sharedViewLevelParamExtended';
import '@app/app/index.css';
import { AgGridReact } from 'ag-grid-react';
import { ViewLevels } from '@app/utils/constants';

export type Positions = AccountGroupLevelResponse | AccountLevelResponse;

const numberFilter = { filter: 'agNumberColumnFilter' };
const columnType = {
  symbolValueColumn: {
    ...numberFilter,
    type: ['numericColumn', 'symbolValueColumn'],
    autoHeight: true,
  },
  percentageColumn: {
    ...numberFilter,
    type: ['numericColumn', 'percentageColumn'],
    autoHeight: true,
  },
  volumeColumn: {
    ...numberFilter,
    type: ['numericColumn', 'volumeColumn'],
    autoHeight: true,
  },
};

const generateCommonColumnDefs = (excludedFields: string[] = []) => {
  const baseCommonDefs = [
    {
      field: 'value',
      ...columnType.symbolValueColumn,
    },
    {
      field: 'cashAmount',
      ...columnType.symbolValueColumn,
    },
    {
      field: 'cashPercentage',
      ...columnType.percentageColumn,
    },
    {
      field: 'units',
      ...columnType.volumeColumn,
    },
    {
      field: 'costBase',
      ...columnType.symbolValueColumn,
    },
    {
      field: 'holdingValue',
      ...columnType.symbolValueColumn,
    },
    {
      field: 'gainLoss',
      ...columnType.symbolValueColumn,
    },
    {
      field: 'taxableGainLoss',
      ...columnType.symbolValueColumn,
    },
    {
      field: 'weight',
      ...columnType.percentageColumn,
    },
  ];

  return baseCommonDefs.filter((col) => !excludedFields.includes(col.field));
};

export const usePositionsGrid = (
  viewLevel: SharedViewLevelParamExtended,
  dispatch: AppDispatch,
  gridRef: RefObject<AgGridReact>,
  accountGroupItems: AccountGroupsState['accountGroupItems'],
  viewData: AccountGroupsState['viewData'],
) => {
  const accountTableConfig = useAppSelector(
    (state: RootState) => state.tableConfig.data.position_accounts,
  );
  const groupsTableConfig = useAppSelector(
    (state: RootState) => state.tableConfig.data.position_groups,
  );
  const positionsTableConfig = useMemo(
    () =>
      viewLevel === 'accountGroups' ? groupsTableConfig : accountTableConfig,
    [viewLevel, accountTableConfig, groupsTableConfig],
  );

  const columnDefs = useMemo((): ColDef[] => {
    const accountPositionsColumnDefs = [
      { field: 'selected', hide: true },
      {
        field: 'account',
        autoHeight: true,
      },
      { field: 'accountGroupCode', autoHeight: true },
      {
        field: 'accountGroupName',
        autoHeight: true,
      },
      { field: 'externalAccountId', autoHeight: true },
      { field: 'portfolio', autoHeight: true },
      { field: 'serviceType', autoHeight: true },
      { field: 'targetSet', autoHeight: true },
      { field: 'taxType', autoHeight: true },
      {
        field: 'accountId',
        filter: 'agNumberColumnFilter',
        type: ['numericColumn'],
        hide: true,
      },
      ...generateCommonColumnDefs(),
    ];

    const groupPositionsColumnDefs = [
      { field: 'selected', hide: true },
      {
        field: 'accountGroupCode',
        autoHeight: true,
      },
      {
        field: 'accountGroupName',
        autoHeight: true,
      },
      { field: 'portfolio', autoHeight: true },
      { field: 'allocationTarget', autoHeight: true },
      { field: 'serviceType', autoHeight: true },
      { field: 'targetSet', autoHeight: true },
      { field: 'planType', autoHeight: true },
      { field: 'investmentAdvisor', autoHeight: true },
      { field: 'riskProfile', autoHeight: true },
      ...generateCommonColumnDefs(['taxableGainLoss', 'gainLoss']),
    ];

    return viewLevel === ViewLevels.accountGroups
      ? groupPositionsColumnDefs
      : accountPositionsColumnDefs;
  }, [viewLevel]);

  const handleOnSelectedAndFilteredEvent = useCallback(
    (event: SelectionChangedEvent | FilterChangedEvent) => {
      const updatedRows: Positions[] = [];
      const filterAndSelectedRows: Positions[] = [];
      event.api.forEachNode((node) => {
        const isSelected = node.isSelected() && node.displayed;
        updatedRows.push({ ...node.data, selected: isSelected });
        if (isSelected) {
          filterAndSelectedRows.push({ ...node.data });
        }
      });
      dispatch(setSelectedPositions(filterAndSelectedRows));
      const updatedData = { ...accountGroupItems.data };
      updatedData[viewData] = updatedRows;
      dispatch(updatePositionSelection(updatedData));
    },
    [dispatch, accountGroupItems.data, viewData],
  );

  const onFirstDataRendered = useCallback(
    (params: FirstDataRenderedEvent<Positions>) => {
      params.api.applyColumnState({
        state: positionsTableConfig.data as ColumnState[],
        applyOrder: true,
      });
      const nodesToSelect: IRowNode[] = [];
      params.api.forEachNode((node) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (node.data?.selected) {
          nodesToSelect.push(node);
        }
      });
      params.api.setNodesSelected({ nodes: nodesToSelect, newValue: true });
    },
    [positionsTableConfig.data],
  );

  const getAccountLevelRowId = useCallback(
    (params: GetRowIdParams<AccountLevelResponse>) => {
      return params.data.accountId.toString();
    },
    [],
  );

  const getAccountGroupLevelRowId = useCallback(
    (params: GetRowIdParams<AccountGroupLevelResponse>) => {
      return params.data.accountGroupCode;
    },
    [],
  );

  const updateSelectedRows = useCallback(() => {
    if (gridRef?.current?.api) {
      gridRef.current.api.selectAll();
    }
  }, [gridRef]);

  const handleUpdateColumnState = useCallback(
    (event: BSAColumnState) =>
      updateColumnsState(event, positionsTableConfig, dispatch),
    [dispatch, positionsTableConfig],
  );

  return {
    columnDefs,
    handleOnSelectedAndFilteredEvent,
    onFirstDataRendered,
    getAccountGroupLevelRowId,
    getAccountLevelRowId,
    updateSelectedRows,
    handleUpdateColumnState,
  };
};

interface Props {
  gridRef: RefObject<AgGridReact>;
}

function PositionsGrid({ gridRef }: Readonly<Props>) {
  const dispatch = useAppDispatch();
  const accountGroupItems = useAppSelector(
    (state: RootState) => state.accountGroups.accountGroupItems,
  );
  const viewData = useAppSelector(
    (state: RootState) => state.accountGroups.viewData,
  );
  const viewLevel = useAppSelector(
    (state: RootState) => state.accountGroups.viewLevel,
  );
  const includeZeroHoldings = useAppSelector(
    (state: RootState) => state.accountGroups.includeZeroHoldings,
  );

  const data = accountGroupItems.data[viewData];
  const processedCount = accountGroupItems.processedCount;
  const { loading, error } = accountGroupItems;

  const {
    columnDefs,
    handleOnSelectedAndFilteredEvent,
    onFirstDataRendered,
    getAccountGroupLevelRowId,
    getAccountLevelRowId,
    updateSelectedRows,
    handleUpdateColumnState,
  } = usePositionsGrid(
    viewLevel,
    dispatch,
    gridRef,
    accountGroupItems,
    viewData,
  );

  useEffect(() => {
    updateSelectedRows();
  }, [updateSelectedRows, includeZeroHoldings]);

  return (
    <IressPanel
      padding={IressPanel.Padding.Sm}
      data-testid="account-groups"
      className="grid-container"
    >
      {data.length > 0 && loading === 'succeeded' ? (
        <AgGrid<Positions>
          gridRef={gridRef}
          rowData={data}
          columnDefs={columnDefs}
          onSelectionChanged={handleOnSelectedAndFilteredEvent}
          onFirstDataRendered={onFirstDataRendered}
          onFilterChanged={handleOnSelectedAndFilteredEvent}
          getRowId={
            viewLevel === ViewLevels.accountGroups
              ? getAccountGroupLevelRowId
              : getAccountLevelRowId
          }
          rowSelection={{
            mode: 'multiRow',
            checkboxes: true,
            headerCheckbox: true,
            enableClickSelection: false,
          }}
          onSortChanged={handleUpdateColumnState}
          onColumnVisible={handleUpdateColumnState}
          onColumnMoved={handleUpdateColumnState}
        />
      ) : (
        <IressStack gutter={IressStack.Gutter.Md}>
          <IressText align={IressText.Align.Center} data-testid="no-rows-text">
            {loading === 'pending'
              ? `${processedCount} rows are loaded. Please wait, it might take some time.`
              : 'No rows to show. The filtered results will be displayed here.'}
          </IressText>
          <IressText align={IressText.Align.Center}>
            {loading === 'pending' && <Spinner />}
            {loading === 'failed' && error}
          </IressText>
        </IressStack>
      )}
    </IressPanel>
  );
}

export default PositionsGrid;
