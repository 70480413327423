import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTableConfig, saveTableConfig } from '@app/services/tableConfig';
import { TABLE_CONFIG_CODES } from './tableConfigSlice';
import { SharedTableConfig } from '@bsa/shared-types';

export const fetchTableConfigsThunk = createAsyncThunk(
  'TableConfig/fetchTableConfigs',
  async () => {
    const responses = await Promise.allSettled(
      Object.values(TABLE_CONFIG_CODES).map((code) => {
        return getTableConfig(code);
      }),
    );
    const fulfilled = responses.filter(
      (result): result is PromiseFulfilledResult<SharedTableConfig> =>
        result.status === 'fulfilled',
    );
    if (fulfilled.length > 0) {
      return fulfilled.map((result) => result.value);
    } else {
      throw new Error('All requests failed');
    }
  },
);

export const saveTableConfigThunk = createAsyncThunk(
  'TableConfig/saveTableConfig',
  async (config: SharedTableConfig) => {
    return await saveTableConfig(config);
  },
);
