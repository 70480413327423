import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PageState {
  path: string;
}

export const initialPageState: PageState = {
  path: '',
};
export const PageSlice = createSlice({
  name: 'Page',
  initialState: initialPageState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<PageState['path']>) => {
      state.path = action.payload;
    },
    setPageState: (state, action: PayloadAction<PageState>) => {
      Object.assign(state, action.payload);
    },
    resetPageState: (state) => {
      Object.assign(state, initialPageState);
    },
  },
});

export const { setCurrentPage, setPageState, resetPageState } =
  PageSlice.actions;
export default PageSlice.reducer;
