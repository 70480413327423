import { RootState } from '@app/app/store';
import { compressData, decompressData } from '@app/utils';
import axiosInstance from '@app/utils/axiosInstance';
import axios from 'axios';

const instancePath = '/save-progress';

export async function saveProgress(state: RootState) {
  const response = await axiosInstance.post(instancePath, {
    data: compressData(state),
  });
  return response.data;
}

export async function deleteSavedSession() {
  const response = await axiosInstance.delete(instancePath);
  return response.status;
}

export async function getProgress() {
  try {
    const response = await axiosInstance.get(instancePath);

    return decompressData(response.data);
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 404) return '';
    throw error;
  }
}
