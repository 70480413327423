import { useCallback } from 'react';
import {
  IressCol,
  IressForm,
  IressField,
  IressRadio,
  IressRadioGroup,
  IressRow,
  IressText,
  IressStack,
  IressInline,
  ButtonMode,
} from '@iress/components-react';
import {
  fetchAccountsHoldingSecurity,
  setViewLevel,
  updateProcessedCount,
} from '@app/features/AccountGroups';
import { useAppDispatch, useAppSelector } from '@app/app/hooks';
import { RootState } from '@app/app/store';
import { isNumeric, modifySecuritiesToMenuItems } from '@app/utils';
import {
  fetchSelectedSecurityMarketPrice,
  setSelectedSecurity,
} from '@app/features/Securities';
import { useComboBoxInputChange } from '@app/hooks';
import { ComboBox } from '../ComboBox';
import { ButtonSubmit } from '../Button';
import { SharedViewLevelParamExtended } from '@app/types/sharedViewLevelParamExtended';

interface Props {
  resetErrorMessage: () => void;
}

function SearchForm({ resetErrorMessage }: Readonly<Props>) {
  const dispatch = useAppDispatch();

  const accountGroups = useAppSelector(
    (state: RootState) => state.accountGroups,
  );
  const existingSecurity = useAppSelector(
    (state: RootState) => state.securities.selectedSecurity,
  );
  const { handleComboBoxInputChange, securities, noResultText } =
    useComboBoxInputChange();

  const handleSubmit = useCallback(
    (event: { [key: string]: unknown }) => {
      const viewLevel = event.viewLevel as SharedViewLevelParamExtended;
      const selectedSecurityId = event.securityId;
      resetErrorMessage();

      if (
        isNumeric(selectedSecurityId) &&
        accountGroups.dataItems &&
        accountGroups.dataItems.length > 0
      ) {
        void dispatch(
          fetchAccountsHoldingSecurity({
            selectedSecurityId: Number(selectedSecurityId),
            accountGroupCodes: accountGroups.dataItems.map(
              (item) => item.code as string,
            ),
            viewLevel,
            updateProcessedCount: (count: number) =>
              dispatch(updateProcessedCount(count)),
          }),
        );
        const selectedSecurity =
          securities.find(
            (security) => security.securityId === Number(selectedSecurityId),
          ) ?? existingSecurity;
        dispatch(setSelectedSecurity(selectedSecurity));
        void dispatch(
          fetchSelectedSecurityMarketPrice({
            selectedSecurityId: Number(selectedSecurityId),
          }),
        );
        dispatch(setViewLevel(viewLevel));
      }
    },
    [
      accountGroups.dataItems,
      dispatch,
      securities,
      existingSecurity,
      resetErrorMessage,
    ],
  );

  return (
    <IressForm
      onSubmit={handleSubmit}
      data-testid="search-form"
      disabledClearOnSubmit
      hiddenErrorSummary
    >
      <IressRow gutter={IressRow.Gutter.Xl}>
        <IressCol span={IressCol.Span.Six}>
          <IressField label="Security" data-testid="security-label">
            <IressStack gutter={IressStack.Gutter.Sm}>
              <IressText
                variant={IressText.Variant.Body}
                data-testid="security-hint"
              >
                Account groups available for filtering:{' '}
                <b>{accountGroups.dataItems.length}</b>
              </IressText>
              <ComboBox
                handleInputChange={handleComboBoxInputChange}
                noResultsText={noResultText}
                selectedOption={
                  modifySecuritiesToMenuItems([existingSecurity])?.[0]
                }
                dataTestId="test-combobox"
                name="securityId"
              />
            </IressStack>
          </IressField>
        </IressCol>
        <IressInline>
          <IressCol alignSelf={IressCol.AlignSelf.Start}>
            <IressField label="Show results by" data-testid="results-label">
              <IressStack gutter={IressStack.Gutter.Sm}>
                <IressText
                  variant={IressText.Variant.Body}
                  data-testid="results-hint"
                >
                  Defines the level where adjustments are applied
                </IressText>
                <IressRadioGroup
                  name="viewLevel"
                  layout={IressRadioGroup.Layout.InlineFlex}
                  required
                  validationMessage="Please select a level"
                  value={accountGroups.viewLevel}
                  data-testid="viewLevel-radio-group"
                >
                  <IressRadio
                    value="accountGroups"
                    data-testid="account-groups-rad"
                  >
                    Account groups
                  </IressRadio>
                  <IressRadio value="accounts" data-testid="accounts-rad">
                    Accounts
                  </IressRadio>
                </IressRadioGroup>
              </IressStack>
            </IressField>
          </IressCol>
        </IressInline>
        <IressCol
          span={IressCol.Span.One}
          alignSelf={IressCol.AlignSelf.Center}
        >
          <ButtonSubmit
            buttonText="Filter"
            mode={ButtonMode.Secondary}
            dataTestId="submit-btn"
            isLoaderVisible={
              accountGroups.accountGroupItems.loading === 'pending'
            }
          />
        </IressCol>
      </IressRow>
    </IressForm>
  );
}
export default SearchForm;
