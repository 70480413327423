import { useAppSelector } from '@app/app/hooks';
import { RootState } from '@app/app/store';
import {
  FilterSelectEventDetail,
  IressField,
  IressFilter,
  MenuItemMeta,
} from '@iress/components-react';
import { useMemo } from 'react';

function SelectExcludeServiceType(
  props: Readonly<{
    onSelect: (e: FilterSelectEventDetail) => void;
  }>,
) {
  const excludedServicetypes = useAppSelector(
    (state: RootState) => state.instructions.excludedServiceTypes,
  );
  const selected: MenuItemMeta[] = useMemo(() => {
    return excludedServicetypes.map((serviceType: string) => ({
      value: serviceType,
      label: serviceType,
    }));
  }, [excludedServicetypes]);

  const options: MenuItemMeta[] = useMemo(() => {
    return [
      { value: 'Advisory', label: 'Advisory' },
      { value: 'Discretionary', label: 'Discretionary' },
      { value: 'Execution Only', label: 'Execution Only' },
    ];
  }, []);
  return (
    <IressField
      label="Exclude Service Types"
      data-testid="exclude-service-type-label"
    >
      <IressFilter
        label="Select an option"
        data-testid="exclude-service-type-filter"
        onSelect={props.onSelect}
        selected={selected}
        multiSelect
        options={options}
      />
    </IressField>
  );
}
export default SelectExcludeServiceType;
