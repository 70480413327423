import React from 'react';
import { Modal } from '@app/components/Modal';

type ApplyInstructionErrorModalProps = object;

const ApplyInstructionErrorModal: React.FC<
  ApplyInstructionErrorModalProps
> = () => {
  const applyInstructionErrorModalId = 'modal-apply-instruction-error';
  const applyInstructionErrorMessage =
    'Your progress has been saved. Please try again later.';

  return (
    <Modal
      id={applyInstructionErrorModalId}
      dataTestId={applyInstructionErrorModalId}
      title="Unable to complete request"
      message={applyInstructionErrorMessage}
    />
  );
};

export default ApplyInstructionErrorModal;
