import {
  IressCol,
  IressForm,
  IressRow,
  ButtonMode,
  IressField,
  IressSelect,
  FormFieldStatus,
} from '@iress/components-react';
import { InputUnitPrice, InputPercentage } from '../Input';
import { ButtonSubmit } from '../Button';
import ApplyInstructionErrorModal from '../InstructionsForm/ApplyInstructionErrorModal';
import { SwitchingToSecurity } from '../SwitchingToSecurity';
import { useComboBoxInputChange } from '@app/hooks';
import { useAppDispatch, useAppSelector } from '@app/app/hooks';
import { RootState } from '@app/app/store';
import { setSwitchToSecurities } from '@app/features/Switch';
import { useCallback } from 'react';
import { modifySecuritiesToMenuItems } from '@app/utils';
import { SelectedLevels, ViewLevels } from '@app/utils/constants';
import { useInstructionState } from '../InstructionsForm/hooks/useInstructionState';
import { SharedSecurityDetailsResponse } from '@bsa/shared-types';

const SwitchForm = () => {
  const dispatch = useAppDispatch();
  const selectedSecurity = useAppSelector(
    (state: RootState) => state.securities.selectedSecurity,
  );
  const viewLevel = useAppSelector(
    (state: RootState) => state.accountGroups.viewLevel,
  );
  const existingSwitchToSecurities = useAppSelector(
    (state: RootState) => state.switch.switchToSecurities,
  );

  const selectedLevel =
    viewLevel === ViewLevels.accountGroups
      ? SelectedLevels.accountGroup
      : SelectedLevels.account;

  const { handleComboBoxInputChange, securities, noResultText } =
    useComboBoxInputChange([
      useAppSelector((state: RootState) => state.switch.switchToSecurities)
        .securityId,
      selectedSecurity.securityId,
    ]);

  const handleSwitchSecurityChange = useCallback(
    ({ value }: FormFieldStatus<HTMLElement>) => {
      const selectedSwitchToSecurity = securities.find(
        (security) => security.securityId === Number(value),
      );

      dispatch(
        setSwitchToSecurities(
          selectedSwitchToSecurity || ({} as SharedSecurityDetailsResponse),
        ),
      );
    },
    [dispatch, securities],
  );

  const instructionState = useInstructionState();

  const {
    handlers: { handlePercentageChange },
  } = instructionState;

  const inputCurrentHolding = useAppSelector(
    (state: RootState) => state.instructions.inputtedCurrentHolding,
  );

  const inputFirstAllocation = useAppSelector(
    (state: RootState) => state.instructions.inputtedFirstAllocation,
  );

  const PercentageErrorText = 'Please enter a value between 0.00 and 100.00';

  return (
    <IressForm
      data-testid="switch-form"
      disabledClearOnSubmit
      hiddenErrorSummary
    >
      <IressRow
        gutter={IressRow.Gutter.Lg}
        verticalAlign={IressRow.VerticalAlign.Bottom}
        horizontalAlign={IressRow.HorizontalAlign.Left}
      >
        <IressCol span={IressCol.Span.Three}>
          <IressField
            label="Instruction"
            hint={`Adjustments will be applied at an ${selectedLevel} level`}
            data-testid="instruction-label"
            valueMissing="Please select an instruction"
          >
            {
              <IressSelect
                required
                placeholder="Select an instruction"
                data-testid="instruction-select"
                value="switch"
              >
                <option key="switch" value="switch">
                  Switch from selected security
                </option>
              </IressSelect>
            }
          </IressField>
        </IressCol>
        <IressCol span={IressCol.Span.Auto}>
          <InputUnitPrice />
        </IressCol>
        <IressCol span={IressCol.Span.Auto}>
          <InputPercentage
            label="Current Holding"
            key="CurrentHolding"
            dataTestId="current-holding"
            min="0.01"
            max="100.00"
            step="0.01"
            valueMissing="Please enter a current holding"
            rangeOverflow={PercentageErrorText}
            rangeUnderflow={PercentageErrorText}
            stepMismatch={PercentageErrorText}
            handleChange={handlePercentageChange}
            inputValue={inputCurrentHolding}
          />
        </IressCol>
        <IressCol span={IressCol.Span.Four}>
          <IressField
            label="Switching to"
            data-testid="switching-security-label"
          >
            <SwitchingToSecurity
              id="switching-security-1"
              cbHandleInputChange={handleComboBoxInputChange}
              cbNoResultsText={noResultText}
              cbDataTestId="switching-security-combobox-1"
              cbOnchange={handleSwitchSecurityChange}
              cbSelectedOption={
                modifySecuritiesToMenuItems([existingSwitchToSecurities])?.[0]
              }
            />
          </IressField>
        </IressCol>
        <IressCol span={IressCol.Span.Auto}>
          <InputPercentage
            label="Allocation"
            key="Allocation-1"
            dataTestId="allocation-1"
            min="0.01"
            max="100.00"
            step="0.01"
            valueMissing="Please enter an allocation"
            rangeOverflow={PercentageErrorText}
            rangeUnderflow={PercentageErrorText}
            stepMismatch={PercentageErrorText}
            handleChange={handlePercentageChange}
            inputValue={inputFirstAllocation}
          />
        </IressCol>
        <IressCol span={IressCol.Span.One}>
          <ButtonSubmit
            key="apply"
            buttonText="Apply"
            dataTestId="submit-btn"
            mode={ButtonMode.Primary}
            isLoaderVisible={false}
          />
        </IressCol>
      </IressRow>
      <ApplyInstructionErrorModal />
    </IressForm>
  );
};

export default SwitchForm;
