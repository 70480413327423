import { Instructions } from '@app/components/Select/InstructionsOptions';
import {
  SelectChangeEvent,
  InputKeyboardEvent,
  FilterSelectEventDetail,
  MenuItemMeta,
} from '@iress/components-react';
import { useCallback } from 'react';
import { CurrentValues, PreviousValues } from './InstructionsInterfaces';
interface UseInstructionEventHandlersParams {
  currentValues: CurrentValues;
  previousValues: PreviousValues;
  viewLevel: string;
}
import { useAppDispatch } from '@app/app/hooks';
import {
  setExcludedServiceTypes,
  setInputtedPercentage,
  setInstructionsChanged,
  setPreviousExcludedServiceTypes,
  setPreviousInputtedPercentage,
  setPreviousSelectedAllocation,
  setPreviousSelectedInstruction,
  setSelectedAllocation,
  setSelectedInstruction,
  setShowAllocationType,
  setShowExcludeServiceType,
  setShowPercentage,
  setShowUnitPrice,
} from '@app/features/Instructions';
import { ViewLevels } from '@app/utils/constants';

export const useInstructionEventHandlers = ({
  currentValues,
  previousValues,
  viewLevel,
}: UseInstructionEventHandlersParams) => {
  const dispatch = useAppDispatch();
  const {
    selectedInstruction,
    inputtedPercentage,
    selectedAllocation,
    excludedServiceTypes,
  } = currentValues;
  const {
    previousSelectedInstruction,
    previousInputtedPercentage,
    previousSelectedAllocation,
  } = previousValues;

  const handleInstructionChange = useCallback(
    (event: SelectChangeEvent) => {
      dispatch(setPreviousSelectedInstruction(selectedInstruction));
      dispatch(setPreviousInputtedPercentage(inputtedPercentage));
      dispatch(setPreviousSelectedAllocation(selectedAllocation));
      dispatch(setPreviousExcludedServiceTypes(excludedServiceTypes));

      dispatch(setSelectedInstruction(event.target.value));
      if (
        selectedInstruction !== previousSelectedInstruction ||
        selectedInstruction !== ''
      ) {
        dispatch(setInstructionsChanged(true));
      }

      switch (event.target.value) {
        case Instructions.sellAll.option: {
          dispatch(setShowPercentage(false));
          dispatch(setShowUnitPrice(true));
          dispatch(setShowAllocationType(false));
          dispatch(setShowExcludeServiceType(false));
          break;
        }
        case Instructions.increase.option: {
          dispatch(setShowPercentage(true));
          dispatch(setShowUnitPrice(true));
          if (viewLevel === ViewLevels.accountGroups) {
            dispatch(setShowAllocationType(true));
            dispatch(setShowExcludeServiceType(true));
          } else {
            dispatch(setShowAllocationType(false));
            dispatch(setShowExcludeServiceType(false));
          }
          break;
        }
        case Instructions.decrease.option: {
          dispatch(setShowPercentage(true));
          dispatch(setShowUnitPrice(true));
          dispatch(setShowAllocationType(false));
          dispatch(setShowExcludeServiceType(false));
          break;
        }
        default: {
          dispatch(setShowUnitPrice(false));
          dispatch(setShowPercentage(false));
          dispatch(setShowAllocationType(false));
          dispatch(setShowExcludeServiceType(false));
        }
      }
    },
    [
      dispatch,
      excludedServiceTypes,
      inputtedPercentage,
      previousSelectedInstruction,
      selectedAllocation,
      selectedInstruction,
      viewLevel,
    ],
  );

  const handlePercentageChange = useCallback(
    (event: InputKeyboardEvent) => {
      dispatch(setInputtedPercentage(event.target.value));
      if (
        inputtedPercentage !== previousInputtedPercentage ||
        inputtedPercentage !== ''
      ) {
        dispatch(setInstructionsChanged(true));
      }
    },
    [dispatch, inputtedPercentage, previousInputtedPercentage],
  );

  const handleAllocationTypeChange = useCallback(
    (event: SelectChangeEvent) => {
      if (
        selectedAllocation !== previousSelectedAllocation ||
        previousSelectedAllocation !== ''
      ) {
        dispatch(setInstructionsChanged(true));
      }
      dispatch(setSelectedAllocation(event.target.value));
    },
    [dispatch, previousSelectedAllocation, selectedAllocation],
  );

  const handleExcludeServiceTypeChange = useCallback(
    (event: FilterSelectEventDetail) => {
      const newExcludedServiceTypes = (event.selected as MenuItemMeta[]).map(
        (item) => item.value,
      );

      if (
        JSON.stringify(newExcludedServiceTypes) !==
        JSON.stringify(excludedServiceTypes)
      ) {
        dispatch(setInstructionsChanged(true));
        dispatch(setPreviousExcludedServiceTypes(excludedServiceTypes));
        dispatch(setExcludedServiceTypes(newExcludedServiceTypes));
      }
    },
    [dispatch, excludedServiceTypes],
  );

  return {
    handleInstructionChange,
    handlePercentageChange,
    handleAllocationTypeChange,
    handleExcludeServiceTypeChange,
  };
};
