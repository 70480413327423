import { useMemo } from 'react';
import { UNLEASH_TOGGLES } from '@app/app/unleash';
import { useFlag } from '@unleash/proxy-client-react';
import { useInstructionEventHandlers } from './useInstructionsEventHandlers';
import { useAppSelector } from '@app/app/hooks';
import { RootState } from '@app/app/store';
import {
  CurrentValues,
  PreviousValues,
  UiState,
} from './InstructionsInterfaces';
import {
  SelectChangeEvent,
  InputKeyboardEvent,
  FilterSelectEventDetail,
} from '@iress/components-react';
import { SelectedLevels, ViewLevels } from '@app/utils/constants';

interface InstructionState {
  currentValues: CurrentValues;
  previousValues: PreviousValues;
  uiState: UiState;
  handlers: {
    handleInstructionChange: (event: SelectChangeEvent) => void;
    handlePercentageChange: (event: InputKeyboardEvent) => void;
    handleAllocationTypeChange: (event: SelectChangeEvent) => void;
    handleExcludeServiceTypeChange: (event: FilterSelectEventDetail) => void;
  };
}

export const useInstructionState = (): InstructionState => {
  const isSaveProgressEnabled = useFlag(UNLEASH_TOGGLES.SaveProgress);
  const viewLevel = useAppSelector(
    (state: RootState) => state.accountGroups.viewLevel,
  );
  const originalProposals = useAppSelector(
    (state: RootState) => state.accountGroups.originalProposals,
  );

  const instructionsState = useAppSelector(
    (state: RootState) => state.instructions,
  );

  const instructionsApplied = useAppSelector(
    (state: RootState) => state.instructions.instructionsApplied,
  );

  const selectedLevel =
    viewLevel === ViewLevels.accountGroups
      ? SelectedLevels.accountGroup
      : SelectedLevels.account;

  const currentValues = useMemo(
    () => ({
      excludedServiceTypes: instructionsState.excludedServiceTypes,
      inputtedPercentage: instructionsState.inputtedPercentage,
      originalProposals,
      selectedAllocation: instructionsState.selectedAllocation,
      selectedInstruction: instructionsState.selectedInstruction,
    }),
    [
      instructionsState.excludedServiceTypes,
      instructionsState.inputtedPercentage,
      originalProposals,
      instructionsState.selectedAllocation,
      instructionsState.selectedInstruction,
    ],
  );

  const previousValues = useMemo(
    () => ({
      previousExcludedServiceTypes:
        instructionsState.previousExcludedServiceTypes,
      previousInputtedPercentage: instructionsState.previousInputtedPercentage,
      previousSelectedAllocation: instructionsState.previousSelectedAllocation,
      previousSelectedInstruction:
        instructionsState.previousSelectedInstruction,
    }),
    [
      instructionsState.previousExcludedServiceTypes,
      instructionsState.previousInputtedPercentage,
      instructionsState.previousSelectedAllocation,
      instructionsState.previousSelectedInstruction,
    ],
  );

  const uiState = useMemo(
    () => ({
      instructionsChanged: instructionsState.instructionsChanged,
      instructionsApplied,
      isSaveProgressEnabled,
      viewLevel,
      selectedLevel,
      showAllocationType: instructionsState.showAllocationType,
      showExcludeServiceType: instructionsState.showExcludeServiceType,
      showPercentage: instructionsState.showPercentage,
      showUnitPrice: instructionsState.showUnitPrice,
    }),
    [
      instructionsState.instructionsChanged,
      instructionsApplied,
      isSaveProgressEnabled,
      viewLevel,
      selectedLevel,
      instructionsState.showAllocationType,
      instructionsState.showExcludeServiceType,
      instructionsState.showPercentage,
      instructionsState.showUnitPrice,
    ],
  );

  const {
    handleInstructionChange,
    handlePercentageChange,
    handleAllocationTypeChange,
    handleExcludeServiceTypeChange,
  } = useInstructionEventHandlers({
    currentValues,
    previousValues,
    viewLevel,
  });

  const handlers = useMemo(
    () => ({
      handleInstructionChange,
      handlePercentageChange,
      handleAllocationTypeChange,
      handleExcludeServiceTypeChange,
    }),
    [
      handleAllocationTypeChange,
      handleExcludeServiceTypeChange,
      handleInstructionChange,
      handlePercentageChange,
    ],
  );

  return useMemo(
    () => ({
      currentValues,
      previousValues,
      uiState,
      handlers,
    }),
    [currentValues, previousValues, uiState, handlers],
  );
};
