import { RefObject, useCallback, useMemo } from 'react';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import * as agGridCommunity from 'ag-grid-community';
import { AG_GRID_LICENSE_KEY } from '@iress/ag-grid-license-key';
import '@iress/ag-grid-theme/dist/build/styles.css';
import { AG_THEME_IRESS_ICONS, getHeaderHeight } from '@iress/ag-grid-theme';
import SideBar from '@app/components/SideBar';
import { useAppSelector } from '@app/app/hooks';
import './style.css';
import { USER_FORMATTING_SETTINGS_FALLBACK } from '@app/utils/formatters';
import { GridEventContext, gridOptions } from './gridOptions';
import { SharedSysadminFormattingResponse } from '@bsa/shared-types';

LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY);

interface Props<TData> extends AgGridReactProps {
  rowData: TData[];
  columnDefs: (agGridCommunity.ColDef | agGridCommunity.ColGroupDef)[];
  gridRef?: RefObject<AgGridReact<TData[]>>;
}

export const useSysAdminFormatting = (
  sysAdminFormatting: SharedSysadminFormattingResponse,
) => {
  const memoizedContext: GridEventContext = useMemo(
    () => ({
      sysAdminFormatting:
        Object.keys(sysAdminFormatting).length > 0
          ? sysAdminFormatting
          : USER_FORMATTING_SETTINGS_FALLBACK,
    }),
    [sysAdminFormatting],
  );

  return { memoizedContext };
};

export const useGridUtils = () => {
  const onGridReady = useCallback(
    ({ api }: agGridCommunity.GridReadyEvent): void => {
      api.updateGridOptions({
        headerHeight: 32,
        floatingFiltersHeight: 32,
      });
      api.refreshCells();
    },
    [],
  );
  return { onGridReady };
};

function AgGrid<TData = unknown>({
  rowData,
  columnDefs,
  gridRef,
  ...agProps
}: Readonly<Props<TData>>) {
  const defaultColDef = useMemo<agGridCommunity.ColDef>(
    () => ({
      resizable: true,
      sortable: true,
      autoHeight: true,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      enableRowGroup: true,
      enableCellChangeFlash: true,
    }),
    [],
  );

  const { onGridReady } = useGridUtils();
  const sideBar = SideBar();

  const autoSizeStrategy = useMemo<
    | agGridCommunity.SizeColumnsToFitGridStrategy
    | agGridCommunity.SizeColumnsToFitProvidedWidthStrategy
    | agGridCommunity.SizeColumnsToContentStrategy
  >(() => {
    return {
      type: 'fitCellContents',
    };
  }, []);
  const sysAdminFormatting = useAppSelector((state) => state.sysadmin.settings);
  const { memoizedContext } = useSysAdminFormatting(sysAdminFormatting);

  return (
    <div
      style={{ height: '100%', width: '100%', flex: 1 }}
      className="bulk-security-actions-grid"
      data-testid="grid-table"
    >
      <AgGridReact
        ref={gridRef}
        context={memoizedContext}
        animateRows
        autoSizeStrategy={autoSizeStrategy}
        className="ag-theme-quartz ag-theme-iress-lite iress-view-mode-compact"
        columnDefs={columnDefs}
        columnHoverHighlight={false}
        defaultColDef={defaultColDef}
        headerHeight={getHeaderHeight()}
        icons={AG_THEME_IRESS_ICONS}
        onGridReady={onGridReady}
        rowData={rowData}
        rowHeight={34}
        rowSelection={{
          mode: 'multiRow',
          enableClickSelection: false,
        }}
        sideBar={sideBar}
        suppressColumnVirtualisation
        suppressRowHoverHighlight
        singleClickEdit
        {...gridOptions}
        {...agProps}
      />
    </div>
  );
}

export default AgGrid;
