import { useAppSelector } from '@app/app/hooks';
import { AgGrid, gridOptions } from '@app/components/AgGrid';
import { SharedSwitchAdjustment } from '@bsa/shared-types';
import { IressPanel } from '@iress/components-react';
import { useSwitchGrid } from './hooks/useSwitchGrid';
import { AgGridReact } from 'ag-grid-react';
import { Dispatch, RefObject, SetStateAction } from 'react';
import { useSwitchGridEventHandlers } from './hooks/useEventHandlers';

export const SWITCH_GRID_TEST_ID = 'switch-grid';
export interface SwitchGridProps {
  gridRef: RefObject<AgGridReact>;
  setProposalsCount: Dispatch<SetStateAction<number>>;
  setTotalRowsCount: Dispatch<SetStateAction<number>>;
  setShowValidationErrorMessage: (value: boolean) => void;
}

function SwitchGrid({
  gridRef,
  setProposalsCount,
  setTotalRowsCount,
  setShowValidationErrorMessage,
}: Readonly<SwitchGridProps>) {
  const rowData = useAppSelector((state) => state.switch.switchProposals);
  // -1 = all row groups will be expanded by default
  const groupDefaultExpanded = -1;

  const {
    columnDefs,
    autoGroupColumnDef,
    getRowId,
    getDataPath,
    rowSelection,
    onFirstDataRendered,
    autoSizeStrategy,
  } = useSwitchGrid();
  const { onSelectionOrFilterChanged, onColumnsStateUpdate, onCellEditing } =
    useSwitchGridEventHandlers(
      setProposalsCount,
      setTotalRowsCount,
      setShowValidationErrorMessage,
    );

  return (
    <IressPanel
      padding={IressPanel.Padding.Sm}
      background={IressPanel.Background.Default}
      className="grid-container"
      data-testid={SWITCH_GRID_TEST_ID}
    >
      <AgGrid<SharedSwitchAdjustment>
        {...gridOptions}
        rowSelection={rowSelection}
        rowData={rowData}
        treeData={true}
        columnDefs={columnDefs}
        gridRef={gridRef}
        onFirstDataRendered={onFirstDataRendered}
        onSelectionChanged={onSelectionOrFilterChanged}
        onFilterChanged={onSelectionOrFilterChanged}
        getRowId={getRowId}
        getDataPath={getDataPath}
        autoGroupColumnDef={autoGroupColumnDef}
        groupDefaultExpanded={groupDefaultExpanded}
        stopEditingWhenCellsLoseFocus={true}
        readOnlyEdit={true}
        onCellEditRequest={onCellEditing}
        onSortChanged={onColumnsStateUpdate}
        onColumnVisible={onColumnsStateUpdate}
        onColumnMoved={onColumnsStateUpdate}
        onGridReady={(event) => event.api.autoSizeAllColumns()}
        autoSizeStrategy={autoSizeStrategy}
      />
    </IressPanel>
  );
}

export default SwitchGrid;
