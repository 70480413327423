import type { SharedSecurityDetailsResponse } from '@bsa/shared-types';
import { searchSecuritiesService } from '@app/services/securities';
import { modifySecuritiesToMenuItems } from '@app/utils';
import { useCallback, useState } from 'react';

export function useComboBoxInputChange(excludedSecurityIds: number[] = []) {
  const [securities, setSecurities] = useState<SharedSecurityDetailsResponse[]>(
    [],
  );
  const [noResultText, setNoResultText] = useState<string>('');

  const handleComboBoxInputChange = useCallback(
    async (query: string) => {
      let securitiesData: SharedSecurityDetailsResponse[] = [];
      if (query.length > 2) {
        try {
          securitiesData = await searchSecuritiesService(
            query,
            setNoResultText,
            excludedSecurityIds,
          );
        } catch {
          securitiesData = [];
        }
        setSecurities(securitiesData);
      } else {
        setNoResultText('Please enter at least 3 characters');
      }
      return modifySecuritiesToMenuItems(securitiesData);
    },
    [excludedSecurityIds],
  );

  return { handleComboBoxInputChange, securities, noResultText };
}
