import { useAppDispatch } from '@app/app/hooks';
import { setProposals } from '@app/features/AccountGroups';
import { ViewLevels } from '@app/utils/constants';
import { Adjustment, AccountGroupAdjustment } from '@bsa/shared-types';
import { AgGridReact } from 'ag-grid-react';
import { RefObject, useCallback } from 'react';

interface UseGridInteractionsParams {
  gridRef: RefObject<AgGridReact<any>>;
  viewLevel: string;
  excludedServiceTypes: string[];
}

export const useGridInteractions = ({
  gridRef,
  viewLevel,
  excludedServiceTypes,
}: UseGridInteractionsParams) => {
  const dispatch = useAppDispatch();

  const updateGridData = useCallback(
    (updatedProposals: {
      proposals: (Adjustment | AccountGroupAdjustment)[];
    }) => {
      const updated =
        viewLevel === ViewLevels.accountGroups
          ? flattenAccountGroups(updatedProposals)
          : (updatedProposals.proposals as Adjustment[]);
      dispatch(setProposals(updated));
    },
    [dispatch, viewLevel],
  );

  const selectAllRows = useCallback(() => {
    gridRef.current?.api.forEachNode((node) => {
      node.setSelected(true);
    });
  }, [gridRef]);

  const filterAndSelectRowsByServiceType = useCallback(() => {
    gridRef.current?.api.forEachNode((node) => {
      const data = node.data as Adjustment | undefined;
      const hasValidAccountId = data?.accountId !== '';
      const hasValidServiceType = data?.serviceType !== '';
      const isServiceTypeExcluded = excludedServiceTypes.includes(
        data?.serviceType ?? '',
      );
      const shouldSelectNode =
        hasValidAccountId && hasValidServiceType && !isServiceTypeExcluded;
      node.setSelected(shouldSelectNode);
    });
  }, [gridRef, excludedServiceTypes]);

  return { updateGridData, selectAllRows, filterAndSelectRowsByServiceType };
};

/**
 * Flattens account groups and accounts into a single array
 * @param {({ proposals: (Adjustment | AccountGroupAdjustment)[] })} updatedProposals
 *   proposals: (Adjustment | AccountGroupAdjustment)[];
 * })} updatedProposals
 * @returns Adjustment[]} flattened array of account groups and accounts
 */
function flattenAccountGroups(updatedProposals: {
  proposals: (Adjustment | AccountGroupAdjustment)[];
}): Adjustment[] {
  return (updatedProposals.proposals as AccountGroupAdjustment[]).flatMap(
    (proposal) => [proposal.accountGroup, ...proposal.accounts],
  );
}
