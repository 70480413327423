import { NoActionsPageTitle } from '@app/components/Titles';
import {
  IressAlert,
  IressButton,
  IressInline,
  IressStack,
  IressText,
} from '@iress/components-react';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function useNoActionsPage() {
  const handleOnClick = useCallback(() => {
    window.location.hash = 'thehub';
  }, []);
  return { handleOnClick };
}
function NoActionsPage() {
  const { handleOnClick } = useNoActionsPage();
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/');
  }, [navigate]);

  return (
    <div
      style={{
        height: '100%',
        flexDirection: 'column',
        display: 'flex',
        gap: '8px',
      }}
      data-testid="no-action-page"
    >
      <NoActionsPageTitle />
      <IressAlert status={IressAlert.Status.Warning}>
        <IressStack gutter={IressStack.Gutter.Md}>
          <IressInline verticalAlign={IressInline.VerticalAlign.Middle}>
            <IressText
              element={IressText.Element.H2}
              mode={IressText.Mode.Warning}
            >
              No Bulk Security Actions (BSA) in progress.
            </IressText>
            <IressText element={IressText.Element.Span}>
              To create a new BSA, return to Account Group List.
            </IressText>
          </IressInline>
          <IressButton
            onClick={handleOnClick}
            mode={IressButton.Mode.Secondary}
            data-testid="no-action-page-redirect-button"
          >
            Go to Account Group List
          </IressButton>
        </IressStack>
      </IressAlert>
    </div>
  );
}

export default NoActionsPage;
