import {
  IressField,
  IressSelect,
  SelectChangeEvent,
} from '@iress/components-react';
import { Instructions } from './InstructionsOptions';
import { RootState } from '@app/app/store';
import { useAppSelector } from '@app/app/hooks';

function SelectInstruction(
  props: Readonly<{
    handleChange: (e: SelectChangeEvent) => void;
    selectedLevel: string;
  }>,
) {
  const selectedInstruction = useAppSelector(
    (state: RootState) => state.instructions.selectedInstruction,
  );
  return (
    <IressField
      label="Instruction"
      hint={`Adjustments will be applied at an ${props.selectedLevel} level`}
      data-testid="instruction-label"
      valueMissing="Please select an instruction"
    >
      {
        <IressSelect
          required
          placeholder="Select an instruction"
          data-testid="instruction-select"
          onChange={props.handleChange}
          value={selectedInstruction}
        >
          {Object.values(Instructions).map((instruction) => (
            <option key={instruction.option} value={instruction.option}>
              {instruction.text}
            </option>
          ))}
        </IressSelect>
      }
    </IressField>
  );
}

export default SelectInstruction;
