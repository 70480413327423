import { SharedTableConfig } from '../../sharedTypes';
export const sharedMockTableConfigResponse: SharedTableConfig = {
  config_id: 1,
  code: 'code',
  data: [],
};

export const sharedMockTableConfigPayload: SharedTableConfig = {
  config_id: 0,
  code: 'code',
  data: [],
};

export const sharedMockTableConfigPatchPayload: SharedTableConfig = {
  config_id: 1,
  code: 'code',
  data: [],
};
