import { IressButton, IressStack } from '@iress/components-react';

function ButtonExit(
  props: Readonly<{
    onClick: () => void;
  }>,
) {
  return (
    <IressStack gutter={IressStack.Gutter.Md}>
      <IressButton
        mode={IressButton.Mode.Secondary}
        type={IressButton.Type.Button}
        data-testid="exit-btn"
        onClick={props.onClick}
      >
        Exit
      </IressButton>
    </IressStack>
  );
}

export default ButtonExit;
