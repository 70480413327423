import React from 'react';
import ReactDOM from 'react-dom/client';
import accountGroupJson from '@app/__mocks__/accountGroups.mocks.json';
import { worker } from '@app/__mocks__/mock-worker';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { setupStore } from './store';
import { router } from './router';
import { FlagProvider, useFlag } from '@unleash/proxy-client-react';
import { configureUnleash, UNLEASH_TOGGLES } from './unleash';
import { IressToaster } from '@iress/components-react';
import '@iress/themes/build/css/iress-theme-light.css';
import '@iress/themes/build/css/iress-beta-theme-light.css';
import '@iress/themes/build/css/iress-beta-theme-dark.css';
import '@iress/themes/build/css/iress-theme-corporate.css';
import '@iress/themes/build/css/hostplus-theme-light.css';
import '@iress/themes/build/css/iress-theme-dark.css';
import '@iress/themes/global.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import '@iress/ag-grid-theme/dist/lite/css/all.css';

if (import.meta.env.VITE_MOCK_BFF_RESPONSE) {
  await worker.start();
}
if (process.env.NODE_ENV === 'development') {
  window.PORTFOLIO_BULK_SECURITY_ACTIONS_DATA = {
    data: accountGroupJson,
    mode: 'development',
  };
}
function Main() {
  const isSaveProgressEnabled = useFlag(UNLEASH_TOGGLES.SaveProgress);
  return (
    <Provider store={setupStore({}, isSaveProgressEnabled)}>
      <link
        rel="stylesheet"
        href="https://cdn.iress.com/icons/5.15.4/css/combined.min.css"
        integrity="sha384-O0tKAkjAKb3hzzRO6Q+3v+zLDboFGyCPSPFzMbNdv4weAM/UF5hCJua1gLIjRbXP"
        crossOrigin="anonymous"
      />
      <div className="iress-beta-theme-light iress-view-mode-standard">
        <IressToaster />
        <RouterProvider router={router} />
      </div>
    </Provider>
  );
}

ReactDOM.createRoot(
  document.querySelector('#bulk-security-actions-root')!,
).render(
  <React.StrictMode>
    <FlagProvider unleashClient={configureUnleash()}>
      <Main />
    </FlagProvider>
  </React.StrictMode>,
);
