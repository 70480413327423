// ================Accounts Service Responses Mocks ======================================

import {
  AccountGroupLevelResponse,
  AccountLevelResponse,
  SharedAccountsResponse,
} from '../../sharedTypes';
import { sharedMockServiceType } from '../adjustments';
import { HUNDRED, TEN, THOUSAND, ZERO } from '../constants';

export function sharedCreateMockAccountsWithHoldings(
  overrides = {}
): AccountLevelResponse {
  const defaults = {
    account: 'account-description-1',
    accountGroupCode: 'ALPHA',
    accountGroupName: 'ALPHA',
    accountId: 123456,
    cashAmount: THOUSAND.toString(),
    costBase: HUNDRED.toString(),
    externalAccountId: 'account-code-1',
    gainLoss: ZERO.toString(),
    holdingValue: HUNDRED.toString(),
    portfolio: 'Johnson, Jon',
    selected: true,
    serviceType: sharedMockServiceType.Discretionary,
    targetSet: 'Target Set',
    taxableGainLoss: ZERO.toString(),
    taxType: 'Individual Savings Account',
    units: HUNDRED.toString(),
    value: THOUSAND.toString(),
    weight: TEN.toString(),
    exclude_from_modelling: false,
  };

  const completeData = { ...defaults, ...overrides };

  return {
    ...(completeData as AccountLevelResponse),
  };
}

export function sharedCreateMockAccountsWithZeroHoldings(
  overrides = {}
): AccountLevelResponse {
  const defaults = sharedCreateMockAccountsWithHoldings();
  const completeData = { ...defaults, ...overrides };
  return {
    ...completeData,
    costBase: ZERO.toString(),
    targetSet: 'Target Set',
    gainLoss: ZERO.toString(),
    holdingValue: ZERO.toString(),
    value: THOUSAND.toString(),
    cashAmount: THOUSAND.toString(),
    serviceType: sharedMockServiceType.Discretionary,
    taxableGainLoss: ZERO.toString(),
    units: ZERO.toString(),
    weight: ZERO.toString(),
    selected: true,
  };
}

export function sharedCreateMockAccountGroupViewResponse(
  overrides = {}
): AccountGroupLevelResponse {
  const defaults = {
    accountGroupCode: 'ALPHA',
    accountGroupName: 'ALPHA',
    accounts: [sharedCreateMockAccountsWithHoldings()],
    allocationTarget: 'Allocation Target',
    cashAmount: HUNDRED.times(9).toString(),
    cashPercentage: ZERO.toString(),
    costBase: HUNDRED.times(2).toString(),
    holdingValue: HUNDRED.times(2).toString(),
    investmentAdvisor: 'John, Investor',
    planType: 'Investment',
    portfolio: 'Johnson, Jon',
    riskProfile: 'Risk Profile',
    selected: true,
    serviceType: 'IAS',
    targetSet: 'Target Set',
    units: HUNDRED.times(2).toString(),
    value: THOUSAND.toString(),
    weight: TEN.times(2).toString(),
  };

  const completeData = { ...defaults, ...overrides };

  return {
    ...(completeData as AccountGroupLevelResponse),
  };
}

const sharedMockAccountsWithHoldings: AccountLevelResponse[] = [
  sharedCreateMockAccountsWithHoldings(),
  sharedCreateMockAccountsWithHoldings({
    account: 'account-description-2',
    accountId: 123455,
    externalAccountId: 'account-code-2',
    portfolio: 'Johnson, Jane',
    exclude_from_modelling: false,
  }),
];

export const sharedMockAccountsWithZeroHoldings =
  sharedCreateMockAccountsWithZeroHoldings({
    account: 'account-description-c1',
    accountGroupCode: 'CHARLIE',
    accountGroupName: 'CHARLIE',
    accountId: 123454,
    externalAccountId: 'account-code-c1',
    portfolio: 'Cowell, Charles',
    targetSet: 'Target Set',
    taxType: 'General Investment Account',
    exclude_from_modelling: false,
  });

export const sharedMockAccountsViewResponse: SharedAccountsResponse = {
  withoutZeroHoldings: sharedMockAccountsWithHoldings,
  withZeroHoldings: [
    ...sharedMockAccountsWithHoldings,
    sharedMockAccountsWithZeroHoldings,
  ],
};

const sharedMockGroupsHoldings: AccountGroupLevelResponse[] = [
  sharedCreateMockAccountGroupViewResponse({
    accounts: sharedMockAccountsWithHoldings,
  }),
];
export const sharedMockAccountGroupsViewResponse: SharedAccountsResponse = {
  withoutZeroHoldings: sharedMockGroupsHoldings,
  withZeroHoldings: [
    ...sharedMockGroupsHoldings,
    sharedCreateMockAccountGroupViewResponse({
      accountGroupCode: 'CHARLIE',
      accountGroupName: 'CHARLIE',
      accounts: [sharedMockAccountsWithZeroHoldings],
      costBase: ZERO.toString(),
      holdingValue: ZERO.toString(),
      portfolio: 'Cowell, Charles',
      units: ZERO.toString(),
      value: THOUSAND.toString(),
      weight: ZERO.toString(),
      cashAmount: THOUSAND.toString(),
    }),
  ] as AccountGroupLevelResponse[],
};
