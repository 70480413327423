import { RootState } from '@app/app/store';
import { initialSavedProgressState } from '@app/features/SavedProgress';
import { initialSysadminState } from '@app/features/Sysadmin';
import {
  initialTableConfigState,
  TABLE_CONFIG_CODES,
} from '@app/features/TableConfig';
import {
  AccountGroupLevelResponse,
  AccountLevelResponse,
  Adjustment,
  SharedColumnData,
  SharedSwitchAdjustment,
  SharedTableConfig,
} from '@bsa/shared-types';

export function getSelectedRowIds(
  items: (Adjustment | AccountLevelResponse | AccountGroupLevelResponse)[],
) {
  return items.map((item) =>
    'accountId' in item && item.accountId
      ? item.accountId.toString()
      : item.accountGroupCode,
  );
}

export function getSwitchRowId(item: SharedSwitchAdjustment) {
  return 'securityId' in item && item.securityId
    ? `${item.accountId}-${item.securityId}`
    : item.accountId.toString();
}
export function getSelectedSwitchesRowIds(items: SharedSwitchAdjustment[]) {
  return items.map((item) => getSwitchRowId(item));
}

export function getSelectedRowIdsSet(items: Array<string>) {
  return new Set(items);
}

export function modifyStateForSavingProgress(state: RootState) {
  return {
    ...state,
    savedProgress: initialSavedProgressState,
    sysadmin: initialSysadminState,
    tableConfig: initialTableConfigState,
  };
}

export function modifyColumnsStatePayload(
  columns: SharedColumnData[],
  code: keyof typeof TABLE_CONFIG_CODES,
  columnMappings: Record<string, number>,
  config: number = 0,
): SharedTableConfig {
  const data = columns.map((column) => {
    const xplanColumnId = columnMappings[column.colId] ?? 0;
    return {
      ...column,
      xplanColumnId,
    };
  });
  return {
    code,
    config_id: config,
    data,
  };
}
