import { SharedTableConfig } from '@bsa/shared-types';
import { TableConfigExtended } from './tableConfigSlice';
import { debounce } from 'lodash';
import { AppDispatch } from '@app/app/store';
import { saveTableConfigThunk } from './tableConfigThunk';
import { DEBOUNCE_TIME } from '@app/utils/constants';

export function mapColumnCodesToXplanColumnIds(config: SharedTableConfig) {
  return config.data.reduce(
    (acc: TableConfigExtended['columnIdsMapping'], column) => {
      acc[column.colId] = column.xplanColumnId ?? 0;
      return acc;
    },
    {},
  );
}

export const debounceSaveTableConfig = debounce(
  (dispatch: AppDispatch, payload: SharedTableConfig) =>
    void dispatch(saveTableConfigThunk(payload)),
  DEBOUNCE_TIME,
);
