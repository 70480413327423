import { useCallback } from 'react';
import { showModal } from '@iress/components-react';

interface UseModalHandlingParams {
  isSaveProgressEnabled: boolean;
  instructionsChanged: boolean;
}

export const useModalHandling = ({
  isSaveProgressEnabled,
}: UseModalHandlingParams) => {
  const handleError = useCallback(() => {
    if (isSaveProgressEnabled) {
      showModal('modal-apply-instruction-error', true);
    }
  }, [isSaveProgressEnabled]);

  return {
    handleError,
  };
};
