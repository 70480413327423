import { SharedSwitchAdjustment, SharedSwitchType } from '@bsa/shared-types';
import { ValueGetterParams } from 'ag-grid-community';

export function getSwitchTypeValue(
  params: ValueGetterParams<SharedSwitchAdjustment>,
) {
  return params.data?.switchType === SharedSwitchType.UNKNOWN
    ? ''
    : params.data?.switchType;
}
