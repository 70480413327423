import { createSwitchInstruction } from '@app/services/switch';
import { SharedSwitchPayload } from '@bsa/shared-types';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const createSwitchInstructionThunk = createAsyncThunk(
  'Switch/createSwitchInstruction',
  async ({ sellSecurity, buySecurities, adjustments }: SharedSwitchPayload) => {
    return createSwitchInstruction(sellSecurity, buySecurities, adjustments);
  },
);
