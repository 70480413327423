import React from 'react';
import { ButtonSecondaryWithOnClick, ButtonPrimary } from '../Button';
import { Modal } from '@app/components/Modal';
import { showModal } from '@iress/components-react';

interface ApplyInstructionModalProps {
  handleApplyNewInstruction: () => void;
}

export const applyInstructionModalId = 'modal-applyInstruction';

const ApplyInstructionModal: React.FC<ApplyInstructionModalProps> = ({
  handleApplyNewInstruction,
}) => {
  const applyInstructionModalButtons = [
    <ButtonSecondaryWithOnClick
      key="Cancel"
      buttonText="Cancel"
      dataTestId="apply-new-btn-cancel"
      onClick={() => showModal(applyInstructionModalId, false)}
    />,
    <ButtonPrimary
      key="apply"
      buttonText="Apply new"
      dataTestid="apply-new-btn-apply-new"
      onClick={handleApplyNewInstruction}
      link={''}
    />,
  ];

  return (
    <Modal
      id={applyInstructionModalId}
      dataTestId={applyInstructionModalId}
      title="Apply new instruction?"
      message="If you apply a new instruction, your current adjustments will not be saved."
      buttons={applyInstructionModalButtons}
    />
  );
};

export default ApplyInstructionModal;
