import {
  FormFieldStatus,
  IressCombobox,
  IressIcon,
  MenuItemMeta,
} from '@iress/components-react';
import './ComboBox.css';

interface Props {
  handleInputChange: (query: string) => Promise<MenuItemMeta[]>;
  noResultsText?: string;
  selectedOption?: MenuItemMeta;
  dataTestId?: string;
  name?: string;
  placeholder?: string;
  className?: string;
  valueMissingText?: string;
  onChange?: (e: FormFieldStatus<HTMLElement>) => void;
}

/**
 * ComboBox component to search and select a security
 * @returns ComboBox component
 */
function ComboBox({
  handleInputChange,
  noResultsText,
  selectedOption,
  dataTestId,
  name,
  placeholder,
  className,
  valueMissingText,
  onChange,
}: Readonly<Props>) {
  return (
    <IressCombobox
      data-testid={dataTestId}
      name={name}
      className={className ?? 'combobox'}
      resultLimitDesktop={15}
      resultLimitMobile={10}
      placeholder={placeholder ?? 'Search security by code, name, SEDOL, ISIN'}
      required
      valueMissingText={valueMissingText ?? 'Please select a security'}
      options={handleInputChange}
      noResultsText={noResultsText}
      selectedOption={selectedOption}
      onChange={onChange}
    >
      <IressIcon name="search" slot={IressCombobox.Slot.Append} />
    </IressCombobox>
  );
}
export default ComboBox;
