import { SharedTableConfig } from '@bsa/shared-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchTableConfigsThunk,
  saveTableConfigThunk,
} from './tableConfigThunk';
import { mapColumnCodesToXplanColumnIds } from './tableConfigSlice.helpers';
import { ThunkLoadingStatus } from '@app/types/loadingStatus';

export enum TABLE_CONFIG_CODES {
  position_accounts = 'position_accounts',
  position_groups = 'position_groups',
  proposal_accounts = 'proposal_accounts',
  proposal_groups = 'proposal_groups',
  switch_accounts = 'switch_accounts',
}

export interface TableConfigExtended extends SharedTableConfig {
  columnIdsMapping: Record<string, number>;
}
export interface TableConfigData {
  position_accounts: TableConfigExtended;
  position_groups: TableConfigExtended;
  proposal_accounts: TableConfigExtended;
  proposal_groups: TableConfigExtended;
  switch_accounts: TableConfigExtended;
}

export interface TableConfigState {
  data: TableConfigData;
  fetchingLoadingStatus: ThunkLoadingStatus;
  savingLoadingStatus: ThunkLoadingStatus;
}

export const initialTableConfigState: TableConfigState = {
  data: {
    position_accounts: {
      code: TABLE_CONFIG_CODES.position_accounts,
      config_id: 0,
      data: [],
      columnIdsMapping: {},
    },
    position_groups: {
      code: TABLE_CONFIG_CODES.position_groups,
      config_id: 0,
      data: [],
      columnIdsMapping: {},
    },
    proposal_accounts: {
      code: TABLE_CONFIG_CODES.proposal_accounts,
      config_id: 0,
      data: [],
      columnIdsMapping: {},
    },
    proposal_groups: {
      code: TABLE_CONFIG_CODES.proposal_groups,
      config_id: 0,
      data: [],
      columnIdsMapping: {},
    },
    switch_accounts: {
      code: TABLE_CONFIG_CODES.switch_accounts,
      config_id: 0,
      data: [],
      columnIdsMapping: {},
    },
  },
  fetchingLoadingStatus: {
    loading: 'idle',
    message: '',
  },
  savingLoadingStatus: {
    loading: 'idle',
    message: '',
  },
};

export const TableConfigSlice = createSlice({
  name: 'TableConfig',
  initialState: initialTableConfigState,
  reducers: {
    resetTableConfigState: (state) => {
      Object.assign(state, initialTableConfigState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchTableConfigsThunk.fulfilled,
        (state, action: PayloadAction<SharedTableConfig[]>) => {
          action.payload.forEach((config) => {
            if (config.code in TABLE_CONFIG_CODES) {
              state.data[config.code as keyof TableConfigData].config_id =
                config.config_id;
              state.data[config.code as keyof TableConfigData].data =
                config.data;
              state.data[
                config.code as keyof TableConfigData
              ].columnIdsMapping = mapColumnCodesToXplanColumnIds(config);
            }
          });
          state.fetchingLoadingStatus.loading = 'succeeded';
        },
      )
      .addCase(fetchTableConfigsThunk.rejected, (state) => {
        state.fetchingLoadingStatus.loading = 'failed';
        state.fetchingLoadingStatus.message = 'Failed to fetch table configs';
      })
      .addCase(fetchTableConfigsThunk.pending, (state) => {
        state.fetchingLoadingStatus.loading = 'pending';
        state.fetchingLoadingStatus.message = 'Loading table configs';
      })
      .addCase(
        saveTableConfigThunk.fulfilled,
        (state, action: PayloadAction<SharedTableConfig>) => {
          if (action.payload.code in TABLE_CONFIG_CODES) {
            state.data[action.payload.code as keyof TableConfigData].data =
              action.payload.data;
            state.data[action.payload.code as keyof TableConfigData].config_id =
              action.payload.config_id;
            state.data[
              action.payload.code as keyof TableConfigData
            ].columnIdsMapping = mapColumnCodesToXplanColumnIds(action.payload);
          }
          state.savingLoadingStatus.loading = 'succeeded';
        },
      )
      .addCase(saveTableConfigThunk.rejected, (state) => {
        state.savingLoadingStatus.loading = 'failed';
        state.savingLoadingStatus.message = 'Failed to fetch table configs';
      })
      .addCase(saveTableConfigThunk.pending, (state) => {
        state.savingLoadingStatus.loading = 'pending';
        state.savingLoadingStatus.message = 'Loading table configs';
      });
  },
});

export const { resetTableConfigState } = TableConfigSlice.actions;
export default TableConfigSlice.reducer;
