import axiosInstance, { chunkArray } from '@app/utils/axiosInstance';
import {
  SharedSwitchAdjustment,
  SharedSwitchPayload,
  SharedSwitchResponse,
  SharedSwitchSecurity,
} from '@bsa/shared-types';
import { AxiosResponse } from 'axios';

const CHUNK_SIZE = 100;

export async function createSwitchInstruction(
  sellSecurity: SharedSwitchSecurity,
  buySecurities: SharedSwitchSecurity[],
  adjustments: SharedSwitchAdjustment[],
): Promise<SharedSwitchResponse> {
  const chunks = chunkArray(adjustments, CHUNK_SIZE);

  const promises = chunks.map((adjustmentsChunk) =>
    axiosInstance.post(`/switch`, {
      sellSecurity,
      buySecurities,
      adjustments: adjustmentsChunk,
    } as SharedSwitchPayload),
  );

  // Wait for all the requests to resolve
  const responses = await Promise.all(promises);

  return responses.reduce(
    (
      data: SharedSwitchResponse,
      response: AxiosResponse<SharedSwitchResponse>,
    ) => {
      data.proposals.push(...response.data.proposals);

      return data;
    },
    { proposals: [] },
  );
}
