import {
  symbolValueFormatter,
  generalNumericFormatter,
  percentageFormatter,
  priceFormatter,
  volumeFormatter,
} from '@app/utils/formatters';
import {
  SharedSecurityDetailsResponse,
  SharedSysadminFormattingResponse,
} from '@bsa/shared-types';
import {
  CellClassParams,
  ColDef,
  GridOptions,
  ValueFormatterParams,
} from 'ag-grid-community';
import { camelCaseToTitle, numberValueGetter } from './getters';

export type GridEventContext = {
  sysAdminFormatting: SharedSysadminFormattingResponse;
};

type ValueFormatterParameters = Pick<
  ValueFormatterParams<unknown, number | null>,
  'value'
> & {
  context: GridEventContext;
};

const iressCssDangerTextVariable = 'var(--iress-default-danger-color)';
const iressCssDefaultTextVariable = 'var(--iress-default-text-color)';
export const baseNumericColumn = {
  valueGetter: numberValueGetter,
  filter: 'agNumberColumnFilter',
};

const numericCellStyle = (params: CellClassParams) => {
  return params.value < 0
    ? { color: iressCssDangerTextVariable }
    : { color: iressCssDefaultTextVariable };
};

export const percentageColumn = {
  ...baseNumericColumn,
  valueGetter: numberValueGetter,
  valueFormatter: ({ context, value }: ValueFormatterParameters): string =>
    percentageFormatter(context.sysAdminFormatting)(value),
  cellStyle: numericCellStyle,
};

export const gridOptions: GridOptions = {
  columnTypes: {
    baseNumericColumn,
    generalNumericColumn: {
      ...baseNumericColumn,
      valueFormatter: ({ context, value }: ValueFormatterParameters) =>
        generalNumericFormatter(context.sysAdminFormatting)(value),
      cellStyle: numericCellStyle,
    },
    symbolValueColumn: {
      ...baseNumericColumn,
      valueFormatter: ({ context, value }: ValueFormatterParameters) => {
        return symbolValueFormatter(context.sysAdminFormatting)(value);
      },
      cellStyle: numericCellStyle,
    },
    priceColumn: {
      ...baseNumericColumn,
      valueFormatter: ({ context, value }: ValueFormatterParameters) =>
        priceFormatter(context.sysAdminFormatting)(value),
      cellStyle: numericCellStyle,
    },
    volumeColumn: {
      ...baseNumericColumn,
      valueFormatter: ({ context, value }: ValueFormatterParameters) =>
        volumeFormatter(context.sysAdminFormatting)(value),
      cellStyle: numericCellStyle,
    },
    percentageColumn,
  },
};

export const symbolColumnDef = {
  filter: 'agNumberColumnFilter',
  type: ['numericColumn', 'symbolValueColumn'],
  autoHeight: true,
};
export const volumeColumnDef = {
  filter: 'agNumberColumnFilter',
  type: ['numericColumn', 'volumeColumn'],
  autoHeight: true,
};
export const percentageColumnDef = {
  filter: 'agNumberColumnFilter',
  type: ['numericColumn', 'percentageColumn'],
  autoHeight: true,
};

export function updateCurrencyTitleInColumns(
  columnDefs: ColDef[],
  security: SharedSecurityDetailsResponse,
  isHierarchicalRow: (params: CellClassParams) => boolean = () => false,
) {
  columnDefs.forEach((columnHeader) => {
    const headerName = columnHeader.field?.replace(
      'CCY',
      security.userCurrency,
    );
    if (headerName) {
      columnHeader.headerName = camelCaseToTitle(headerName);
    }
    columnHeader.cellClassRules = {
      ...columnHeader.cellClassRules,
      'hierarchy-group-row': isHierarchicalRow,
    };
  });
}
