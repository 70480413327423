import type { SharedSecurityDetailsResponse } from '@bsa/shared-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchSelectedSecurityMarketPrice } from './securitiesThunk';
import { LoadingStatus } from '@app/types/loadingStatus';

interface SecuritiesState {
  selectedSecurity: SharedSecurityDetailsResponse;
  status: LoadingStatus;
  message: string;
}
export const initialSecuritiesState: SecuritiesState = {
  selectedSecurity: {} as SharedSecurityDetailsResponse,
  status: 'idle',
  message: '',
};

export const SecuritiesSlice = createSlice({
  name: 'Securities',
  initialState: initialSecuritiesState,
  reducers: {
    setSelectedSecurity: (
      state,
      action: PayloadAction<SharedSecurityDetailsResponse>,
    ) => {
      state.selectedSecurity = action.payload;
    },
    setSecuritiesState: (state, action: PayloadAction<SecuritiesState>) => {
      Object.assign(state, action.payload);
    },
    resetSecuritiesState: (state) => {
      Object.assign(state, initialSecuritiesState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSelectedSecurityMarketPrice.fulfilled, (state, action) => {
        state.selectedSecurity = {
          ...state.selectedSecurity,
          ...action.payload,
        };
        state.status = 'succeeded';
        state.message =
          'Succeeded to fetch market price & user currency for security';
      })
      .addCase(fetchSelectedSecurityMarketPrice.rejected, (state) => {
        state.status = 'failed';
        state.message =
          'Failed to fetch market price & user currency for security';
      })
      .addCase(fetchSelectedSecurityMarketPrice.pending, (state) => {
        state.status = 'pending';
        state.message = 'Loading market price & user currency for security';
      });
  },
});

export const { setSelectedSecurity, setSecuritiesState, resetSecuritiesState } =
  SecuritiesSlice.actions;

export default SecuritiesSlice.reducer;
