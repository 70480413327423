import { IressButton, IressStack } from '@iress/components-react';

function ButtonContinueSwitch(
  props: Readonly<{
    onClick: () => void;
    buttonText: string;
  }>,
) {
  return (
    <IressStack gutter={IressStack.Gutter.Md}>
      <IressButton
        mode={IressButton.Mode.Primary}
        type={IressButton.Type.Button}
        data-testid={`${props.buttonText.toLowerCase()}-btn`}
        onClick={props.onClick}
      >
        {props.buttonText}
      </IressButton>
    </IressStack>
  );
}

export default ButtonContinueSwitch;
