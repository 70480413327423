import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface InstructionsState {
  showUnitPrice: boolean;
  showPercentage: boolean;
  showAllocationType: boolean;
  showExcludeServiceType: boolean;
  previousSelectedInstruction: string;
  previousInputtedCurrentHolding: string;
  previousInputtedFirstAllocation: string;
  previousInputtedPercentage: string;
  previousSelectedAllocation: string;
  previousExcludedServiceTypes: string[];
  selectedInstruction: string;
  inputtedCurrentHolding: string;
  inputtedFirstAllocation: string;
  inputtedPercentage: string;
  selectedAllocation: string;
  excludedServiceTypes: string[];
  instructionsChanged: boolean;
  instructionsApplied: boolean;
}

export const initialInstructionsState: InstructionsState = {
  showUnitPrice: false,
  showPercentage: false,
  showAllocationType: false,
  showExcludeServiceType: false,
  previousSelectedInstruction: '',
  previousInputtedCurrentHolding: '',
  previousInputtedFirstAllocation: '',
  previousInputtedPercentage: '',
  previousSelectedAllocation: '',
  previousExcludedServiceTypes: [],
  selectedInstruction: '',
  inputtedCurrentHolding: '',
  inputtedFirstAllocation: '',
  inputtedPercentage: '',
  selectedAllocation: '',
  excludedServiceTypes: [],
  instructionsChanged: false,
  instructionsApplied: false,
};

export const InstructionsSlice = createSlice({
  name: 'Instructions',
  initialState: initialInstructionsState,
  reducers: {
    // State
    setInstructionsState: (state, action: PayloadAction<InstructionsState>) => {
      Object.assign(state, action.payload);
    },
    resetInstructionsState: (state) => {
      Object.assign(state, initialInstructionsState);
    },

    // UI
    setShowUnitPrice: (state, action: PayloadAction<boolean>) => {
      state.showUnitPrice = action.payload;
    },
    setShowPercentage: (state, action: PayloadAction<boolean>) => {
      state.showPercentage = action.payload;
    },
    setShowAllocationType: (state, action: PayloadAction<boolean>) => {
      state.showAllocationType = action.payload;
    },
    setShowExcludeServiceType: (state, action: PayloadAction<boolean>) => {
      state.showExcludeServiceType = action.payload;
    },

    // Previous Instructions
    setPreviousSelectedInstruction: (state, action: PayloadAction<string>) => {
      state.previousSelectedInstruction = action.payload;
    },
    setPreviousInputtedCurrentHolding: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.previousInputtedCurrentHolding = action.payload;
    },
    setPreviousInputtedFirstAllocation: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.previousInputtedFirstAllocation = action.payload;
    },
    setPreviousInputtedPercentage: (state, action: PayloadAction<string>) => {
      state.previousInputtedPercentage = action.payload;
    },
    setPreviousSelectedAllocation: (state, action: PayloadAction<string>) => {
      state.previousSelectedAllocation = action.payload;
    },
    setPreviousExcludedServiceTypes: (
      state,
      action: PayloadAction<string[]>,
    ) => {
      state.previousExcludedServiceTypes = action.payload;
    },

    // Instructions
    setSelectedInstruction: (state, action: PayloadAction<string>) => {
      state.selectedInstruction = action.payload;
    },
    setInputtedCurrentHolding: (state, action: PayloadAction<string>) => {
      state.inputtedCurrentHolding = action.payload;
    },
    setInputtedFirstAllocation: (state, action: PayloadAction<string>) => {
      state.inputtedFirstAllocation = action.payload;
    },
    setInputtedPercentage: (state, action: PayloadAction<string>) => {
      state.inputtedPercentage = action.payload;
    },
    setSelectedAllocation: (state, action: PayloadAction<string>) => {
      state.selectedAllocation = action.payload;
    },
    setExcludedServiceTypes: (state, action: PayloadAction<string[]>) => {
      state.excludedServiceTypes = action.payload;
    },
    setInstructionsChanged: (state, action: PayloadAction<boolean>) => {
      state.instructionsChanged = action.payload;
    },
    setInstructionsApplied: (state, action: PayloadAction<boolean>) => {
      state.instructionsApplied = action.payload;
    },
  },
});

export const {
  setInstructionsState,
  resetInstructionsState,
  setShowUnitPrice,
  setShowPercentage,
  setShowAllocationType,
  setShowExcludeServiceType,
  setPreviousSelectedInstruction,
  setInputtedCurrentHolding,
  setInputtedFirstAllocation,
  setPreviousInputtedPercentage,
  setPreviousSelectedAllocation,
  setPreviousExcludedServiceTypes,
  setSelectedInstruction,
  setPreviousInputtedCurrentHolding,
  setPreviousInputtedFirstAllocation,
  setInputtedPercentage,
  setSelectedAllocation,
  setExcludedServiceTypes,
  setInstructionsChanged,
  setInstructionsApplied,
} = InstructionsSlice.actions;

export default InstructionsSlice.reducer;
