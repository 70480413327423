import Decimal from 'decimal.js';
import { AccountGroupAdjustment, Adjustment } from '../../sharedTypes';
import {
  sharedCreateMockAccountAdjustment,
  sharedCreateMockAccountGroupAdjustment,
} from '../adjustments';
import { HUNDRED, TEN, THOUSAND } from '../constants';

const value = THOUSAND;
const weight = TEN;
const units = HUNDRED;
const decreaseFactor = new Decimal(0.5);

const updatedVales: Partial<Adjustment> = {
  'targetValue (CCY)': THOUSAND.toString(),
  'proposedValue (CCY)': value.times(decreaseFactor).toString(),
  targetNativeValue: value.toString(),
  proposedNativeValue: value.times(decreaseFactor).toString(),
  targetUnits: units.toString(),
  proposedUnits: value.times(decreaseFactor).toString(),
  targetWeight: weight.toString(),
  proposedWeight: value.times(decreaseFactor).toString(),
};

export const sharedMockManualAdjustmentResponse: Adjustment =
  sharedCreateMockAccountAdjustment({ ...updatedVales });

export const sharedMockAccountGroupManualAdjustmentResponse: AccountGroupAdjustment =
  {
    accountGroup: sharedCreateMockAccountGroupAdjustment({ ...updatedVales }),
    accounts: [sharedMockManualAdjustmentResponse],
  };
