import { PayloadAction } from '@reduxjs/toolkit';
import { AccountGroupsState } from './accountGroupsSlice';
import { Adjustment } from '@bsa/shared-types';
export function updateProposalHelper(
  state: AccountGroupsState,
  action: PayloadAction<AccountGroupsState['proposals']>,
): AccountGroupsState['proposals'] {
  const updateMap = new Map<string, Adjustment>();

  // Create a Map for quick lookups
  for (const adjustment of action.payload) {
    const key = adjustment.accountId || adjustment.accountGroupCode;
    if (key) {
      updateMap.set(key, adjustment);
    }
  }

  // Update the proposals, only if key is found in the map
  return state.proposals.map((proposal) => {
    const key = proposal.accountId || proposal.accountGroupCode;
    return key && updateMap.has(key) ? updateMap.get(key)! : proposal;
  });
}
