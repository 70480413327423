import { SharedViewLevelParamExtended } from '@app/types/sharedViewLevelParamExtended';
import {
  AccountGroupAdjustment,
  Adjustment,
  SharedManualAdjustmentType,
} from '@bsa/shared-types';
import { CellEditRequestEvent, CellValueChangedEvent } from 'ag-grid-community';

import {
  accountGroupsManualAdjustments,
  manualAdjustments,
} from '@app/services/manualAdjustments';
import { getManualAdjustmentTypeFromColumn } from './ProposalsGrid.utils';
import { AppDispatch } from '@app/app/store';
import { updateProposal } from '@app/features/AccountGroups';
import { ViewLevels } from '@app/utils/constants';

/**
 * Handle manual adjustment when the user changes the value of a editable cell
 * calls manual adjustment service to update the row data based on the new value
 */
export async function handleManualAdjustment(
  event: CellValueChangedEvent<Adjustment> | CellEditRequestEvent<Adjustment>,
  viewLevel: SharedViewLevelParamExtended,
  dispatch: AppDispatch,
): Promise<void> {
  const updatedAdjustments: Adjustment[] = [];
  const { column, newValue, data, node, oldValue } = event;

  const originalData = { ...data, [column.getColId()]: oldValue };
  const fieldName = getManualAdjustmentTypeFromColumn(column);

  if (fieldName === null) return;

  if (viewLevel === ViewLevels.accountGroups) {
    if (!node.parent?.data) return;
    const accountGroupAdjustment: AccountGroupAdjustment = {
      accountGroup: node.parent.data,
      accounts: [originalData],
    };
    const { accountGroup: updatedAccountGroup, accounts } =
      await accountGroupsManualAdjustments(
        fieldName as SharedManualAdjustmentType,
        newValue,
        accountGroupAdjustment,
      );
    // The response contains the updated account group and one account only
    const updatedAccount = accounts[0];
    updatedAdjustments.push(updatedAccount, updatedAccountGroup);
  } else {
    const response = await manualAdjustments(
      fieldName as SharedManualAdjustmentType,
      newValue,
      originalData,
    );
    updatedAdjustments.push(response);
  }
  dispatch(updateProposal(updatedAdjustments));
}
