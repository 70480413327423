import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useMemo,
} from 'react';
import {
  AgGrid,
  BSAColumnState,
  gridOptions,
  percentageColumnDef,
  symbolColumnDef,
  updateColumnsState,
  volumeColumnDef,
} from '@app/components/AgGrid';
import {
  SharedSecurityDetailsResponse,
  type AccountLevelResponse,
  type Adjustment,
} from '@bsa/shared-types';

import { useAppDispatch, useAppSelector } from '@app/app/hooks';
import { AppDispatch, RootState } from '@app/app/store';
import { AgGridReact } from 'ag-grid-react';
import { IressPanel } from '@iress/components-react';
import '@app/app/index.css';
import { handleManualAdjustment } from './ProposalsGrid.handlers';
import {
  CellClassParams,
  CellEditRequestEvent,
  CellValueChangedEvent,
  ColDef,
  EditableCallbackParams,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GetRowIdParams,
  IGroupCellRendererParams,
  SelectionChangedEvent,
  SortDirection,
  RowSelectionOptions,
  GroupSelectionMode,
  ColumnState,
} from 'ag-grid-community';
import { SharedViewLevelParamExtended } from '@app/types/sharedViewLevelParamExtended';
import { camelCaseToTitle } from '../AgGrid/getters';
import { setSelectedProposals } from '@app/features/AccountGroups';
import { getSelectedRowIdsSet } from '@app/utils/modifiers.utils';
import { ViewLevels } from '@app/utils/constants';

export type Proposal = Adjustment;

interface Props {
  gridRef: RefObject<AgGridReact>;
  setProposalsCount: Dispatch<SetStateAction<number>>;
  setTotalRowsCount: Dispatch<SetStateAction<number>>;
  setShowValidationErrorMessage: (value: boolean) => void;
}

interface AdjustmentData {
  accountId: number;
  proposedUnits: string;
}

interface ProposalsGridParams {
  security: SharedSecurityDetailsResponse;
  viewLevel: SharedViewLevelParamExtended;
  dispatch: AppDispatch;
  setProposalsCount: Dispatch<SetStateAction<number>>;
  setTotalRowsCount: Dispatch<SetStateAction<number>>;
  setShowValidationErrorMessage: (value: boolean) => void;
  selectedProposals: string[];
}

const isHierarchicalRow = (params: CellClassParams<Adjustment>) => {
  return params?.data?.accountId === '';
};

const editableColumn = {
  editable: (params: EditableCallbackParams<Adjustment>) => {
    const isHierarchyRow = params?.data?.accountId === '';

    return params.node.isSelected() && !isHierarchyRow;
  },
  cellClass: (params: CellClassParams<Adjustment>) => {
    return [
      'editable-cell-text',
      isHierarchicalRow(params) ? '' : 'editable-cell',
    ];
  },
};

export const useProposalsGrid = ({
  security,
  viewLevel,
  dispatch,
  setProposalsCount,
  setTotalRowsCount,
  setShowValidationErrorMessage,
  selectedProposals,
}: ProposalsGridParams) => {
  const rowSelection: RowSelectionOptions = useMemo(
    () => ({
      mode: 'multiRow',
      headerCheckbox: viewLevel !== ViewLevels.accountGroups, // Enable header checkbox only when applicable
      checkboxes: viewLevel !== ViewLevels.accountGroups, // Enable checkboxes only when applicable
      ...(viewLevel === ViewLevels.accountGroups
        ? { groupSelects: 'descendants' as GroupSelectionMode }
        : {}),
      enableClickSelection: false,
    }),
    [viewLevel],
  );

  const columnDefs = useMemo((): ColDef[] => {
    const commonColumnDefs = [
      { field: 'externalAccountId', autoHeight: true, hide: true },
      { field: 'instrument', autoHeight: true },
      { field: 'SEDOL', autoHeight: true, hide: true },
      { field: 'ISIN', autoHeight: true, hide: true },
      { field: 'security', autoHeight: true, hide: true },
      {
        field: 'marketPrice (CCY)',
        ...symbolColumnDef,
        hide: true,
      },
      {
        field: 'nativePrice',
        ...symbolColumnDef,
        autoHeight: true,
        hide: true,
      },
      { field: 'nativeCurrency', autoHeight: true, hide: true },
      { field: 'assetClassShortName', autoHeight: true, hide: true },
      {
        field: 'portfolioValue',
        ...symbolColumnDef,
        hide: true,
      },
      {
        field: 'currentValue (CCY)',
        ...symbolColumnDef,
      },
      {
        field: 'targetValue (CCY)',
        ...symbolColumnDef,
        ...editableColumn,
      },
      {
        field: 'proposedValue (CCY)',
        ...symbolColumnDef,
        ...editableColumn,
      },
      {
        field: 'targetNativeValue',
        ...symbolColumnDef,
      },
      {
        field: 'proposedNativeValue',
        ...symbolColumnDef,
      },
      {
        field: 'currentUnits',
        ...volumeColumnDef,
      },
      {
        field: 'targetUnits',
        ...volumeColumnDef,
        ...editableColumn,
      },
      {
        field: 'proposedUnits',
        ...volumeColumnDef,
        ...editableColumn,
      },
      {
        field: 'currentWeight',
        ...percentageColumnDef,
      },
      {
        field: 'targetWeight',
        ...percentageColumnDef,
        ...editableColumn,
      },
      {
        field: 'proposedWeight',
        ...percentageColumnDef,
        ...editableColumn,
      },
      {
        field: 'currentCashAvailable (CCY)',
        ...symbolColumnDef,
      },
      {
        field: 'proposedCashAvailable (CCY)',
        ...symbolColumnDef,
      },
      {
        field: 'currentCash %',
        ...percentageColumnDef,
      },
      {
        field: 'assetClass %',
        ...percentageColumnDef,
      },
      { field: 'taxType', autoHeight: true },
      {
        field: 'taxableGain/Loss (CCY)',
        ...symbolColumnDef,
      },
      {
        field: 'gain/Loss (CCY)',
        ...symbolColumnDef,
      },
      { field: 'status', autoHeight: true },
      { field: 'accountId', hide: true },
      { field: 'serviceType', hide: true },
    ];

    const accountGroupCodeColumnDef = [
      {
        field: 'accountGroupCode',
        autoHeight: true,
      },
    ];

    const accountAdjustmentsColumnDefs = [
      {
        field: 'portfolio',
        autoHeight: true,
      },
      ...accountGroupCodeColumnDef,
      { field: 'accountGroup', autoHeight: true, sort: 'asc' as SortDirection },
      { field: 'account', autoHeight: true },
      ...commonColumnDefs,
    ];
    const groupAdjustmentsColumnDefs = [
      { field: 'portfolio', autoHeight: true, hide: true },
      ...accountGroupCodeColumnDef,
      {
        field: 'accountGroup',
        autoHeight: true,
        sort: 'asc' as SortDirection,
        hide: true,
      },
      { field: 'account', autoHeight: true, hide: true },
      ...commonColumnDefs,
    ];
    return viewLevel === ViewLevels.accountGroups
      ? groupAdjustmentsColumnDefs
      : accountAdjustmentsColumnDefs;
  }, [viewLevel]);

  const autoGroupColumnDef = useMemo<ColDef>(() => {
    return {
      headerName: 'Account group & accounts',
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        checkbox: true,
        suppressCount: true,
      } as IGroupCellRendererParams,
      cellClassRules: {
        'hierarchy-group-row': isHierarchicalRow,
      },
    };
  }, []);

  gridOptions.rowSelection = rowSelection;
  const accountTableConfig = useAppSelector(
    (state: RootState) => state.tableConfig.data.proposal_accounts,
  );
  const groupsTableConfig = useAppSelector(
    (state: RootState) => state.tableConfig.data.proposal_groups,
  );
  const proposalsTableConfig = useMemo(
    () =>
      viewLevel === 'accountGroups' ? groupsTableConfig : accountTableConfig,
    [viewLevel, accountTableConfig, groupsTableConfig],
  );
  const onFirstDataRendered = useCallback(
    (params: FirstDataRenderedEvent<AccountLevelResponse>) => {
      params.api.applyColumnState({
        state: proposalsTableConfig.data as ColumnState[],
        applyOrder: true,
      });
      // Convert selectedRowIds to a Set for faster lookup
      const selectedRowIdsSet = getSelectedRowIdsSet(selectedProposals);
      params.api.forEachNode((node) => {
        const rowId = node.id; // This uses the rowId as defined in getRowId
        if (rowId && selectedRowIdsSet.has(rowId)) {
          node.setSelected(true);
        }
      });
    },
    [selectedProposals, proposalsTableConfig],
  );

  const getRowId = useCallback((params: GetRowIdParams<Adjustment>) => {
    return params.data.accountId !== null &&
      params.data.accountId !== undefined &&
      params.data.accountId !== ''
      ? params.data.accountId.toString()
      : params.data.accountGroupCode;
  }, []);

  const getDataPath = useCallback((data: Proposal) => {
    return data.hierarchy;
  }, []);

  const treeData = viewLevel === ViewLevels.accountGroups;

  const handleOnSelectedAndFilteredEvent = useCallback(
    (event: SelectionChangedEvent | FilterChangedEvent) => {
      const filterAndSelectedRows: Adjustment[] = [];
      let filteredTotalRows = 0;
      event.api.forEachNodeAfterFilter((node) => {
        const adjustmentData = node.data as AdjustmentData;
        if (adjustmentData.accountId) {
          filteredTotalRows++;
          if (node.isSelected()) filterAndSelectedRows.push(node.data);
        }
      });
      dispatch(setSelectedProposals(filterAndSelectedRows));
      setProposalsCount(filterAndSelectedRows.length);
      setTotalRowsCount(filteredTotalRows);
      setShowValidationErrorMessage(false);
    },
    [
      setProposalsCount,
      setTotalRowsCount,
      setShowValidationErrorMessage,
      dispatch,
    ],
  );
  columnDefs.forEach((columnHeader) => {
    const headerName = columnHeader.field?.replace(
      'CCY',
      security.userCurrency,
    );
    if (headerName) {
      columnHeader.headerName = camelCaseToTitle(headerName);
    }
    columnHeader.cellClassRules = {
      ...columnHeader.cellClassRules,
      'hierarchy-group-row': isHierarchicalRow,
    };
  });

  const handleOnCellEditing = useCallback(
    (e: CellValueChangedEvent<Adjustment> | CellEditRequestEvent<Adjustment>) =>
      void handleManualAdjustment(e, viewLevel, dispatch),
    [viewLevel, dispatch],
  );

  const handleUpdateColumnState = useCallback(
    (event: BSAColumnState) =>
      updateColumnsState(event, proposalsTableConfig, dispatch),
    [dispatch, proposalsTableConfig],
  );

  return {
    columnDefs,
    handleOnSelectedAndFilteredEvent,
    onFirstDataRendered,
    getRowId,
    getDataPath,
    treeData,
    autoGroupColumnDef,
    handleOnCellEditing,
    handleUpdateColumnState,
  };
};

function ProposalsGrid({
  gridRef,
  setProposalsCount,
  setTotalRowsCount,
  setShowValidationErrorMessage,
}: Readonly<Props>) {
  const dispatch = useAppDispatch();
  const rowData = useAppSelector(
    (state: RootState) => state.accountGroups.proposals,
  );
  const selectedProposals = useAppSelector(
    (state: RootState) => state.accountGroups.selectedProposals,
  );
  const security = useAppSelector(
    (state: RootState) => state.securities.selectedSecurity,
  );
  const viewLevel = useAppSelector(
    (state: RootState) => state.accountGroups.viewLevel,
  );

  const {
    columnDefs,
    autoGroupColumnDef,
    onFirstDataRendered,
    handleOnSelectedAndFilteredEvent,
    getRowId,
    getDataPath,
    treeData,
    handleOnCellEditing,
    handleUpdateColumnState,
  } = useProposalsGrid({
    security,
    viewLevel,
    dispatch,
    setProposalsCount,
    setTotalRowsCount,
    setShowValidationErrorMessage,
    selectedProposals,
  });

  // -1 = all row groups will be expanded by default
  const groupDefaultExpanded = -1;

  return (
    <IressPanel
      padding={IressPanel.Padding.Sm}
      background={IressPanel.Background.Default}
      className="grid-container"
      data-testid="proposals"
    >
      <AgGrid<Proposal>
        {...gridOptions}
        rowData={rowData}
        treeData={treeData}
        columnDefs={columnDefs}
        gridRef={gridRef}
        onFirstDataRendered={onFirstDataRendered}
        onSelectionChanged={handleOnSelectedAndFilteredEvent}
        onFilterChanged={handleOnSelectedAndFilteredEvent}
        getRowId={getRowId}
        getDataPath={getDataPath}
        autoGroupColumnDef={autoGroupColumnDef}
        groupDefaultExpanded={groupDefaultExpanded}
        onCellValueChanged={handleOnCellEditing}
        stopEditingWhenCellsLoseFocus={true}
        readOnlyEdit={true}
        onCellEditRequest={handleOnCellEditing}
        onSortChanged={handleUpdateColumnState}
        onColumnVisible={handleUpdateColumnState}
        onColumnMoved={handleUpdateColumnState}
      />
    </IressPanel>
  );
}

export default ProposalsGrid;
