import axiosInstance from '@app/utils/axiosInstance';
import { SharedTableConfig } from '@bsa/shared-types';

export async function getTableConfig(code: string) {
  const response = await axiosInstance.get('/table-config', {
    params: {
      code,
    },
  });
  return response.data;
}

export async function saveTableConfig(config: SharedTableConfig) {
  const response = await axiosInstance.post('/table-config', config);
  return response.data;
}
