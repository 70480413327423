import pako from 'pako';

// Helper functions for base64 encoding and decoding in the browser
export function uint8ArrayToBase64(bytes: Uint8Array): string {
  let binary = '';
  bytes.forEach((byte) => {
    binary += String.fromCodePoint(byte);
  });
  return btoa(binary);
}

export function base64ToUint8Array(base64: string): Uint8Array {
  const binary = atob(base64);
  const bytes = new Uint8Array(binary.length);
  for (let i = 0; i < binary.length; i++) {
    const codePoint = binary.codePointAt(i);
    if (codePoint !== undefined) {
      bytes[i] = codePoint;
    }
  }
  return bytes;
}

export function compressData(data: unknown) {
  const compressedData = pako.gzip(JSON.stringify(data));

  // Convert the compressed data to a base64 string
  return uint8ArrayToBase64(compressedData);
}

export function decompressData(base64Data: string) {
  // Convert the base64 data back to a Uint8Array and decompress it
  const compressedData = base64ToUint8Array(base64Data);
  const decompressedData = pako.ungzip(compressedData, { to: 'string' });

  return JSON.parse(decompressedData);
}
