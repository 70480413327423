import { useAppDispatch, useAppSelector } from '@app/app/hooks';
import { BSAColumnState, updateColumnsState } from '@app/components/AgGrid';
import { setSelectedSwitches } from '@app/features/Switch';
import { SharedSwitchAdjustment } from '@bsa/shared-types';
import { FilterChangedEvent, SelectionChangedEvent } from 'ag-grid-community';
import { Dispatch, SetStateAction, useCallback } from 'react';

export function useSwitchGridEventHandlers(
  setProposalsCount: Dispatch<SetStateAction<number>>,
  setTotalRowsCount: Dispatch<SetStateAction<number>>,
  setShowValidationErrorMessage: (value: boolean) => void,
) {
  const switchTableConfig = useAppSelector(
    (state) => state.tableConfig.data.switch_accounts,
  );

  const dispatch = useAppDispatch();

  const onSelectionOrFilterChanged = useCallback(
    (
      event:
        | SelectionChangedEvent<SharedSwitchAdjustment>
        | FilterChangedEvent<SharedSwitchAdjustment>,
    ) => {
      const filterAndSelectedRows: SharedSwitchAdjustment[] = [];
      let filteredTotalRows = 0;
      event.api.forEachNodeAfterFilter((node) => {
        if (node.data?.securityId) {
          filteredTotalRows++;
          if (node.isSelected()) {
            filterAndSelectedRows.push(node.data);
          }
        }
      });
      dispatch(setSelectedSwitches(filterAndSelectedRows));
      setProposalsCount(filterAndSelectedRows.length);
      setTotalRowsCount(filteredTotalRows);
      setShowValidationErrorMessage(false);
    },
    [
      dispatch,
      setProposalsCount,
      setShowValidationErrorMessage,
      setTotalRowsCount,
    ],
  );
  const onColumnsStateUpdate = useCallback(
    (event: BSAColumnState) =>
      updateColumnsState(event, switchTableConfig, dispatch),
    [dispatch, switchTableConfig],
  );

  const onCellEditing = useCallback(() => {}, []);
  return {
    onSelectionOrFilterChanged,
    onColumnsStateUpdate,
    onCellEditing,
  };
}
